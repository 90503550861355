import Lockr from 'lockr';

export const handleChangeCar = ({ customerCars, customerInfo, selectedOption }) => {
  Lockr.set('ClientData', {
      customerCars,
      customerInfo,
      customerSelectedCar: selectedOption,
  });

  window.location.reload()
};
