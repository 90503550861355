// Dependencias

import React from 'react'
import styled from 'styled-components'
import useForm from 'react-hook-form'

// Componentes
import Header from '../Header'
import Body from '../Body'
import { Container, Row, Col } from 'react-bootstrap'
import Input from '../Input'
import LoaderAnimation from '../LoaderAnimation'

// Imagens
import looviIconImage from '../../assets/images/loovi-logo-icon.png'
import { api_keyed, URLS } from '../../helpers/Request'
import { authHandler } from '../../helpers/auth'
import Version from '../Version'

const Form = (props) => {

    const { register, handleSubmit, errors } = useForm()

    const onSubmit = data => {
        props.proceed(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <span className="loovi-color-0">Código</span>
            <Input
                register={register}
                errors={errors.customerCodigoValidacao}
                required
                errorMessage="Preencha o campo com o código de validação"
                className="placeholder-field-white"
                name="CodigoAutenticacao"
                onChange={props.handleChange} />
            <input className="loovi-button" type="submit" value="Enviar" />
        </form>
    )
}


export class FormCodigoValidacao extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            CodigoAutenticacao: '',
            isLoading: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return { ...prevState, data: nextProps.data }
    }

    componentDidMount() {
        document.body.style.backgroundColor = '#5A78FF'
    }

    proceed = (data) => {
        const { CodigoAutenticacao } = data
        const { Sessao, cpf } = this.state.data;

        this.setState({ isLoading: true })
        api_keyed.post(`${URLS.AUTENTICACAO}/cliente/${cpf}/verifica-codigo`,
            {
                body: JSON.stringify({ Sessao, CodigoAutenticacao })
            }).json().then(data => {
                this.setState({ isLoading: false })
                authHandler.setToken(data.IdToken)
                authHandler.setAccessToken(data.AccessToken);
                authHandler.setRefreshToken(data.RefreshToken);
                this.props.nextStep(data)
            }, (response) => {
                this.setState({ isLoading: false })
                alert('Não foi possível validar código.')
            })
    }

    back = () => {
        this.props.prevStep()
    }


    handleChange = event => {
        const target = event.target;
        const name = target.name
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [name]: value })
    }

    render() {

        //console.log(this.state)

        return (
            <Section>
                <LoaderAnimation show={this.state.isLoading} />
                <Header background="#5A78FF">
                    <figure className="loovi-icon"><img src={looviIconImage} alt="Loovi" /></figure>
                    <span className="loovi-spacer"></span>
                    <span className="loovi-spacer"></span>
                </Header>
                <Container >
                    <Body>
                        <Row>
                            <Col sm={12} md={{ span: 4, offset: 4 }}>
                                <h1 className="loovi-align">Digite o código de validação enviado.</h1>
                                <div className="loovi-spacer" />
                                <Form
                                    handleChange={this.handleChange}
                                    proceed={this.proceed} />
                                <span className="loovi-spacer" />
                                <form>
                                    <div className="loovi-center">
                                        <input type="button" onClick={this.back} className="loovi-link-button" value="Reenviar Código" />
                                    </div>
                                </form>
                                <Version center />
                            </Col>
                        </Row>
                    </Body>
                </Container>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;
    color: #FFFFFF;

    .loovi-icon{

        padding-top: 30px;
    }

    label{

        margin: 0;
    }

    .loovi-field{

        background: transparent;
        border: solid 2px #FFFFFF;
        color: #FFFFFF;
    }


    .loovi-button{

        background: #FFFFFF;
        color: #393043;
    }

    h1{
        font-size: 36px !important;
        color: #FFFFFF;
        letter-spacing: -1.29px;
        line-height: 43px;
    }

    .loovi-link-button{

        color: #FFFFFF !important;
        border-color: #FFFFFF !important;
    }
`

export default FormCodigoValidacao