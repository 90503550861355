// Dependencias

import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserView } from "react-device-detect";

// Componentes

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logo } from '../components/Logo'

export const NavBar = () => {
    const goToAreaDoCliente = () => {
        window.location.href = "/painel";
    }

    return (
        <NavBarContainer>
            <Container>
                <Row className="d-flex align-items-center">
                    <Col xs={6}>
                        <Logo href="/" />
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end align-items-center">
                        <CustomerLink onClick={goToAreaDoCliente}><FontAwesomeIcon icon="lock" /> Área do <span>Cliente</span></CustomerLink>
                        <BrowserView>
                            <Button href="/checkout/contact">Contrate <span>Agora</span></Button>
                        </BrowserView>
                    </Col>
                </Row>
            </Container>
        </NavBarContainer>
    );
}
const NavBarContainer = styled.div`
    padding: 50px 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;

    figure{

        margin: 0;

        img{

            max-width: 133px;

            @media (max-width: 768px){

                max-width: 120px;
            }
        }
    }
`;

const Button = styled.a`
    background: #FFFFFF;
    display: inline-block;
    padding: 15px 35px;
    box-shadow: 0 15px 19px 0 rgba(15,29,66,0.20);
    border-radius: 4px;
    font-size: 13px;
    color: #393043;
    letter-spacing: 1.22px;
    text-align: center;
    text-transform: uppercase;

    &:hover{

        color: #FFFFFF;
        background: #57D9A4;
    }

    @media (max-width: 768px){

        span{

            display: none;
        }
    }
`;

const CustomerLink = styled.a`
    font-size: 13px;
    color: #fff !important;
    text-transform: uppercase;
    margin-right: 50px;
    cursor: pointer;

    &:hover{

        color: #393043 !important;
    }

    .fa-lock{

        margin-right: 10px;
        vertical-align: baseline;
    }

    @media (max-width: 768px){

        margin-right: 0;
        font-size: 12px;
    }
`;
