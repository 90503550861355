import Lockr from "lockr";
import { authHandler } from "../../helpers/auth";
import { URLS, API_BANCO, api_keyed } from "../../helpers/Request";
import { decode as base64_decode, encode as base64_encode } from "base-64";

async function getContratos() {
  let dados = { Contratos: [] };
  let contratos = { Contratos: [] };
  const cpfOrCnpj = authHandler.getCPForCNPJ() || "";

  let encodedString = base64_encode(
    `{"url": "${URLS.SAP}/GetClient/${cpfOrCnpj}/${API_BANCO}", "metodo": "GET", "headers" : { "requester": "SevenApp" }}`,
  );

  if (dados.Contratos.length === 0 && cpfOrCnpj) {
    try {
      const result = await api_keyed
        .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
        .json();

      dados = JSON.parse(base64_decode(result));

      dados.businessPartner.salesContracts =
        dados.businessPartner.salesContracts.filter((contract) => {
          return (
            contract.cancelled === false && contract.status !== "Encerrado"
          );
        });

      if (dados.businessPartner.salesContracts.length > 0) {
        const selectedCar = dados.businessPartner.salesContracts
          .map((contract) => ({
            ...contract.vehicle,
            contractInfo: contract,
          }))
          .map((vehicle, key) => ({
            contractInfo: vehicle.contractInfo,
            label: vehicle.vModel
              ? `${vehicle.numberPlate} - ${vehicle.vModel}`.toUpperCase()
              : vehicle.numberPlate
                ? vehicle.numberPlate.toUpperCase()
                : `Dispositivo ${key + 1}`,
            value: `${key}-${vehicle.numberPlate}`,
          }));

        let customerSelectedCar;
        const clientData = Lockr.get("ClientData");
        if (!!clientData && !!clientData.customerSelectedCar)
          customerSelectedCar = clientData.customerSelectedCar;
        else customerSelectedCar = selectedCar[0];

        const nCtrId = customerSelectedCar.contractInfo.nCtrId;
        contratos = await getIndications(nCtrId);
        customerSelectedCar = {
          ...customerSelectedCar,
          contractDiscounts: contratos.activeIndicationCtt,
        };

        //console.log(contratos)

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: customerSelectedCar.value,
          customerCars: selectedCar,
          customerSelectedCar: customerSelectedCar,
          customerInfo: {
            key: dados.businessPartner.addressType,
            name: dados.businessPartner.cardName,
            email: dados.businessPartner.e_Mail,
            phone: dados.businessPartner.phone1,
            cpf: dados.businessPartner.fiscalID.cpf,
            cnpj: dados.businessPartner.fiscalID.cnpj,
            address: dados.businessPartner.bpAddresses,
          },
        });
      }
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }
}

async function getIndications(contractId) {
  let encodedString = base64_encode(
    `{"url": "${URLS.SAP}/checkindicationctt", "metodo": "GET", "headers" : { "requester": "SevenApp", "nctrid": "${contractId}" }}`,
  );
  try {
    const result = await api_keyed
      .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
      .json();

    const dados = JSON.parse(base64_decode(result));

    return dados;
  } catch (e) {
    console.log(e);
    return false;
  }
}

const getHistoricoDePagamento = async (nCtrId) => {
  const cpfOrCnpj = authHandler.getCPForCNPJ() || "";

  let encodedString = base64_encode(
    `{"url": "${URLS.SAP}/CheckBills", "metodo": "GET", "headers" : { "requester": "SevenApp", "banco": "${API_BANCO}", "cpfcnpj": "${cpfOrCnpj}", "nctrid": "${nCtrId}" }}`,
  );
  console.log(
    `{"url": "${URLS.SAP}/CheckBills", "metodo": "GET", "headers" : { "requester": "SevenApp", "banco": "${API_BANCO}", "cpfcnpj": "${cpfOrCnpj}", "nctrid": "${nCtrId}" }}`,
  );
  try {
    const result = await api_keyed
      .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
      .json();

    const pagamentos = JSON.parse(base64_decode(result));
    console.log("CSH -> ", pagamentos.returnBills);

    return pagamentos.returnBills;
  } catch (error) {
    console.error(error);
    return;
  }
};

export { getContratos, getIndications, getHistoricoDePagamento };
