// Dependencias

import React from 'react'
import styled from 'styled-components'

// Componentes

import Header from '../Header'
import NavBar from './NavBar'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../painel/Sidebar'
import FormCreditCard from './FormCreditCard'

// Imagens

import cardModelImage from '../../assets/images/modelo-cartao.png'

export const AddCreditCardBrowser = (props) => {

    return(
        <Section>
            <Header background="#5A78FF">
                <NavBar background="#5A78FF" titleColor="#FFFFFF" burgerWhite backButton>Trocar Cartão</NavBar>
            </Header>
            <Container fluid>
                <Row>
                    <div className="loovi-fixed-side-column">
                        <Sidebar />
                    </div>
                    <div className="loovi-fluid-column">
                        <div className="loovi-descktop-padding">
                            <Row className="loovi-full">
                                <Col sm={12} md={4}>
                                    <h4  className="loovi-uppercase">Trocar cartão</h4>
                                    <span className="loovi-spacer" />
                                    <figure className="loovi-card"><img src={cardModelImage} alt="Cartão de Crédito"/></figure>
                                </Col>
                                <Col sm={12} md={6}>
                                    <h3 className="loovi-color-5">Insira os dados do novo cartão de crédito</h3>
                                    <FormCreditCard
                                        isAddCreditCard
                                        values={props.values}
                                        handleSelectChange={props.handleSelectChange}
                                        handleChange={props.handleChange}
                                        isChangeCreditCard={true}
                                        proceed={props.proceed}/>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </Section>
    )
}

const Section = styled.section`

    position: relative;

    font-size:15px;

    h4{

        font-size: 12px;
        color: #9FA5B3;
        text-align: left;
        line-height: 21px;
        letter-spacing: 1.8px;
    }

    h3{

        font-size: 18px;
        margin-top: 60px;
        margin-bottom: 0;
    }

    .loovi-info-data{

        p{

            margin: 0;
        }
    }

    .loovi-payment-config{

        background: #EFF2F7;
    }

    .loovi-align-left{

        text-align: left !important;
    }

    .loovi-card{

        img{

            max-width: 100% !important;
            margin: 0 0 -150px 0;
        }
    }

    .loovi-radio{

        opacity: 0.9;
        font-size: 14px;
        color: #899299;
        letter-spacing: -0.5px;
        line-height: 26px;
    }

    .checkmark{

        background: #EFF2F7 !important;
    }
`
export default AddCreditCardBrowser
