import { api, api_keyed, URLS } from "../helpers/Request";
import { decode as base64_decode, encode as base64_encode } from "base-64";

export default class SapService {
  static async getSellers() {
    let encodedString = base64_encode(
      `{"url": "${URLS.SAP}/GetSellers", "metodo": "GET", "headers" : { "requester": "SevenApp" }}`
    );

    try {
      const result = await api_keyed
        .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
        .json();

      const dados = JSON.parse(base64_decode(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async getAdvancePaymentValue(naturalContract) {
    if (naturalContract) {
      let encodedString = `{"url": "${URLS.SAPFATURA}/previsaoValor/${naturalContract}", "metodo": "GET", "headers" : {}, "body" : {}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString), null, 4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      );

      encodedString = btoa(utf8Bytes);

      try {
        const result = await api_keyed
          .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
          .json();
        const dados = JSON.parse(atob(result));

        return dados;
      } catch (e) {
        console.log(e);
        return false;
      }
    } else {
      return false;
    }
  }

  static async getAdvancePaymentInfo(naturalContract, paymentMethod) {
    if (naturalContract && paymentMethod) {
      let encodedString = `{"url": "${URLS.SAPFATURA}/antecipar/", "metodo": "POST", "headers" : {}, "body" : {"contratoNatural" : "${naturalContract}", "metodoPagamento": "${paymentMethod}"}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString), null, 4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      );

      encodedString = btoa(utf8Bytes);

      try {
        const result = await api_keyed
          .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
          .json();
        const dados = JSON.parse(atob(result));
        console.log(dados);
        return dados;
      } catch (e) {
        console.log(e);
        return false;
      }
    } else {
      return false;
    }
  }
}

export const CalcProRataService = async (ctrId, date) => {
  try {
    const result = await api
      .post(`${URLS.AUTENTICAR}`, {
        json: { Username: "SevenApp", Password: "Loovi@SevenApp1" },
      })
      .json();
    const response = await api
      .get(`${URLS.PRORATA}/${ctrId}/${date}`, {
        headers: { authorization: "Bearer " + result.jwtToken },
      })
      .json();
    return response;
  } catch (e) {
    console.error("Erro ao obter calculo proRata:", e);
    return false;
  }
};

export const GerarProRataService = async (ctrId, ticketCrm, date) => {
  try {
    const result = await api.post(URLS.AUTENTICAR, {
      json: {
        "Username": "SevenApp",
        "Password": "Loovi@SevenApp1"
      }
    }).json();

    const response = await api.post(URLS.GERARPRORATA, {
      headers: {
        'authorization': "Bearer " + result.jwtToken,
        'Content-Type': 'application/json'
      },
      json: {
        "contrato": ctrId,
        "ticketCrm": ticketCrm,
        "vendedor": "lia@loovi.com.br",
        "diavencimento": date,
        "gerarAcrescimo": true
      }
    }).json();

    return response;
  } catch (e) {
    console.error("Erro ao obter gerar proRata:", e);
    return false;
  }
};

export const GetPlansService = async (estado) => {
  try {
    const result = await api
      .post(`${URLS.AUTENTICAR}`, {
        json: { Username: "SevenApp", Password: "Loovi@SevenApp1" },
      })
      .json();
    const response = await api
      .get(`${URLS.PLANOS}/${estado}/app`, {
        headers: { authorization: "Bearer " + result.jwtToken },
      })
      .json();
    return response;
  } catch (e) {
    console.error("Erro ao obter planos:", e);
    return false;
  }
};