// Dependencias

import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

import LoaderAnimation from '../LoaderAnimation';

// Componentes

import { MobileView, BrowserView } from "react-device-detect";
import ChangeCreditCardMobile from './ChangeCreditCardMobile';
import ChangeCreditCardBrowser from './ChangeCreditCardBrowser';

import { authHandler } from '../../helpers/auth.js'

export class ChangeCreditCard extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            customerCars: [],
            customerSelectedCar: {},
            isLoading: false,
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        if (authHandler.isLogged()) {
            this.setState(Lockr.get('ClientData'))
        }
        this.setState({ isLoading: false })
    }

    handleSelectChange = selectedOption => {
        this.setState({ isLoading: true })
        //console.log(selectedOption);
        this.setState(
            { customerSelectedCar: selectedOption }
        );

        let clientData;
        
        if (authHandler.isLogged()) {
            clientData = Lockr.get('ClientData');
            //console.log(clientData);

            Lockr.set('ClientData', {
                IdCustomerSelectedCar: clientData.customerSelectedCar.value,
                customerCars: clientData.customerCars,
                customerSelectedCar: selectedOption,
                customerInfo: clientData.customerInfo
            });
                
        }
        this.setState({ isLoading: false })
    };


    render(){

        const {
            customerCars,
            customerSelectedCar,
            isLoading} = this.state

        return(
            <Section>
                <LoaderAnimation show={isLoading} />
                <MobileView>
                    <ChangeCreditCardMobile
                        customerCars={customerCars}
                        customerSelectedCar={customerSelectedCar}
                        handleSelectChange={this.handleSelectChange}/>
                </MobileView>
                <BrowserView>
                    <ChangeCreditCardBrowser
                            customerCars={customerCars}
                            customerSelectedCar={customerSelectedCar}
                            handleSelectChange={this.handleSelectChange}/>
                </BrowserView>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;
    font-size:15px;

    h4{

        font-size: 12px;
        color: #9FA5B3;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        text-align: center;
        line-height: 21px;
        margin-top: 100px;
    }

    .loovi-list-cards{

        border-top: solid 1px #cdd1dd;
        margin-top: 30px;

        img{

            width: 65px;
            height: auto;
            margin-right: 20px;
        }

        &__item{

            padding: 12px 20px;
            border-bottom: solid 1px #cdd1dd;
            height: 60px;
        }
    }

    .loovi-link{

        display: inline-block;
        margin: 20px 0 0 25px;
    }

    .loovi-info-data{

        p{

            margin: 0;
        }
    }

    .loovi-payment-config{

        background: #EFF2F7;
    }

    .loovi-align-left{

        text-align: left !important;
    }

    .loovi-card{

        img{

            max-width: 100% !important;
            margin: 0 0 -150px 0;
        }
    }

    .loovi-radio{

        opacity: 0.9;
        font-size: 14px;
        color: #899299;
        letter-spacing: -0.5px;
        line-height: 26px;
    }

    .checkmark{

        background: #EFF2F7 !important;
    }
`
