// Dependencias

import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import SapService from '../../services/SapService';
import MagicService from '../../services/MagicService';
import Lockr from 'lockr';
import LoaderAnimation from '../LoaderAnimation'

// Components

import LooviSelect from '../styles/LooviSelect'
import Body from '../Body'
import * as masks from '../../helpers/masks'

// Images

import credtiCardPlusImage from '../../assets/images/credit-card-plus-icon.png'
import mastercardImage from '../../assets/images/card-mastercard.png'
import visaImage from '../../assets/images/card-visa.png'

export const PaymentHistoryMobile = (props) => {
    const [isLoading, setLoading] = useState(false)
    let items = 0
    let valorTotal = 0

    let customerCards = []
    const clientData = Lockr.get('ClientData')
    // customerCards = clientData.customerCars.reduce((acc, contract) => {
    //     const { cardSummary, pmCard } = contract.contractInfo
    //     return [...acc, { label: pmCard, cardNum: `**** ${cardSummary}`, cardImage: visaImage }];
    // }, []);
    const { cardSummary, pmCard } = clientData.customerSelectedCar.contractInfo
    if (!!cardSummary && !!pmCard) {
        customerCards = [{ label: pmCard === 'mc' ? 'Cartão de Crédito Mastercard' : pmCard === 'visa' ? 'Cartão de Crédito Visa' : 'Cartão de Crédito Amex', cardNum: `**** **** **** ${cardSummary}`, cardImage: pmCard === 'mc' ? mastercardImage : visaImage }];
    } else {
        customerCards = [{ label: pmCard, cardNum: `**** **** **** ${cardSummary}`, cardImage: credtiCardPlusImage }];
    }

    if (props.payments) {
        items = props.payments.filter(payment=> payment.billStatus.idStatus === 'I')
        valorTotal = items.reduce((acc, current) => {
            const value = acc + (current.docTotal+current.valorMulta+current.valorJuros)
            return value;
        }, 0)
    }

    const callReactivation = async () => {
        await setLoading(true)

        try {
            const response = await SapService.getSellers();
            
            if(response) {
                if(response.sucess){
                    let sellerId = response.sellers.filter(seller  => {
                        return seller.email === "lia@loovi.com.br";
                    });
                    //console.log('sellerId.slpCode',sellerId[0].slpCode);
                    const result = await MagicService.reactivation({
                        contrato: props.payments[0].nCtrId,
                        faturas: items.map(item => ({ fatura: item.docEntry })),
                        valorTotal,
                        IdSAP__c: sellerId[0].slpCode
                    });
                    //console.log(result)

                    if (result) {
                        if (result.retorno === "FALSE" || result.retorno === "false") {
                          alert(result.mensagemRetorno);
                        } else {
                            window.location.reload();
                            //history.push("/pagamentos");
                        }
                      } else {
                        alert('Ops, ocorreu um erro ao processar o pagamento.');
                      }
                }
            }
            //console.log(response) 
            
        } catch (error) {
            alert('Ops, ocorreu um erro ao processar o pagamento.')
        }
        
        await setLoading(false)
    }

    return(
        <Section>
            <LoaderAnimation show={isLoading} />
            <div className="loovi-sub-header">
                <Container>
                    <Row>
                        <Col sm={12} md={{span: 6, offset:3}}>
                            <p className="loovi-color-5 loovi-center loovi-bold">Selecione o veículo</p>
                            <LooviSelect classNamePrefix="react-select"
                                value={props.customerSelectedCar}
                                onChange={props.handleSelectChange}
                                options={props.customerCars} />
                            <div className="loovi-spacer" />
                            <h3 className="loovi-header-title loovi-color-4">Cartão cadastrado</h3>
                            <div className="loovi-cartao-info">
                                <span  className="loovi-color-5 loovi-bold">{customerCards[0].label}</span> 
                                <h4 className="loovi-color-2">{customerCards[0].cardNum}</h4>
                            </div>
                            <div className="loovi-center">
                                <a href="/pagamentos/cartao" className="loovi-change-card">
                                    Trocar Cartão
                                    <img src={credtiCardPlusImage} alt="Card Icon"/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="loovi-full">
                <Body className="loovi-list">
                    <h4>Histórico de Pagamentos</h4>

                    {props.payments && props.payments.map(payment => (
                        <div className="loovi-list-item loovi-color-4 d-flex justify-content-between">
                            <div className="loovi-list-desc">
                                <span className="loovi-bold">{moment(payment.docDueDate).format('MM/YYYY')}</span> - {masks.real(payment.docTotal+payment.valorMulta+payment.valorJuros)} - {payment.billStatus.descStatus}
                            </div>
                            <div className="loovi-list-action">

                            </div>
                        </div>
                    ))}

                    <span class="loovi-spacer"></span>

                    {!!valorTotal && (
                        <div class="loovi-cupom d-flex justify-content-between" style={{ alignItems: "center", marginLeft: 10}}>
                            <span className="loovi-bold">
                                 Total em aberto: {masks.real(valorTotal)}
                            </span>
                            <a href="#pagar" class="loovi-change-card" style={{ color: "white", marginRight: 10, }} onClick={callReactivation}>
                                Pagar Agora
                            </a>
                        </div>
                    )}
                </Body>
            </Container>
        </Section>
    )
}

export default PaymentHistoryMobile

const Section = styled.section`
    position: relative;
`
