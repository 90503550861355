// Dependencias

import React from "react";
import styled from "styled-components";

// Componentes

import { Container } from "react-bootstrap";
import Body from "../Body";

// Imagens

import shieldImage from "../../assets/images/icon-shield.png";
import logoLoovi from "../../assets/images/loovi-logo-color.png";

export class Footer extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <Body>
            <div className="d-flex justify-content-between">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="loovi-link"
                href={`https://contratos-clientes.s3.amazonaws.com/politica-de-privacidade-loovi.pdf`}
              >
                Política de Privacidade
              </a>
              <span className="loovi-shield">Site seguro</span>
            </div>
            <div className="loovi-cnpj d-flex align-items-center">
              <figure className="loovi-logo">
                <img src={logoLoovi} alt="Loovi" />
              </figure>
              <small className="loovi-color-4">34.504.257/0001-00</small>
            </div>
          </Body>
        </Container>
      </Section>
    );
  }
}

const Section = styled.footer`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  .loovi-cnpj {
    margin-top: 5px;
  }

  .loovi-logo {
    margin: 0 10px 0 0;

    img {
      max-width: 53px;
      height: auto;
    }
  }

  small {
    font-size: 11px;
  }

  .loovi-link {
    font-family: "BrownStd-Bold";
    font-size: 15px;
    color: #5a78ff;
    letter-spacing: -0.32px;
    line-height: 21px;
    border-bottom: solid 2px #5a78ff;

    &:hover {
      color: #57d9a3;
      border-color: #57d9a3;
    }
  }

  .loovi-shield {
    font-family: "BrownStd-Bold";
    font-size: 15px;
    color: #5a78ff;
    letter-spacing: -0.32px;
    line-height: 21px;
    padding: 2px 0 2px 30px;
    background-image: url(${shieldImage});
    background-repeat: no-repeat;
    background-size: 20px 24px;
    background-position: top left;

    &:hover {
      color: #57d9a3;
    }
  }
`;

export default Footer;
