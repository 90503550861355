// Dependencias

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history'


// Fontawesome

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faChevronRight, faLock } from '@fortawesome/free-solid-svg-icons';

// Componentes

import { Contact } from './Contact';
import { FAQ } from './FAQ';
import { Painel } from './Painel'
import { Dashboard } from './components/painel/Dashboard'
import { Register } from './components/painel/Register'
import { PaymentsHistory } from './components/painel/PaymentsHistory'
import AdvancePayment from './components/painel/AdvancePayment'
import { ChangeCreditCard } from './components/painel/ChangeCreditCard'
import { AddCreditCard } from './components/painel/AddCreditCard'
import { AdditionalServices } from './components/painel/AdditionalServices'
import { Support } from './components/painel/Support'
import { Signature } from './components/painel/Signature'
import { PrivacyPolicy } from './components/checkout/PrivacyPolicy'
import { TermsOfUse } from './components/checkout/TermsOfUse'
import SSO from './components/SSO/SSO';
import Travels from './components/painel/Travels/index';
import Sinisters from './components/painel/Sinisters';
import { ConsultationSinisters } from './components/painel/ConsultationSinisters';

library.add(faCheckSquare, faCoffee, faChevronRight, faLock, fab)

const history = createBrowserHistory();

function App() {
  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Painel} />

          <Route exact path="/mapa" component={Travels} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/:nCtrId" component={Dashboard} />
          <Route exact path="/meu-plano" component={Signature} />
          <Route exact path="/cadastro" component={Register} />
          <Route exact path="/pagamentos" component={PaymentsHistory} />
          <Route exact path="/pagamentos/cartao" component={ChangeCreditCard} />
          <Route exact path="/pagamentos/adicionar-cartao" component={AddCreditCard} />
          <Route exact path="/pagamentos/:nCtrId" component={PaymentsHistory} />
          <Route exact path="/antecipacao" component={AdvancePayment} />
          <Route exact path="/servicos-adicionais" component={AdditionalServices} />
          <Route exact path="/suporte" component={Support} />
          <Route exact path="/suporte/:nCtrId" component={Support} />
          <Route exact path="/suporte/:nCtrId/:upSell" component={Support} />
          <Route exact path="/sinistros" component={Sinisters} />
          <Route exact path="/sinistros/:caseId" component={ConsultationSinisters} />
          
          <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />
          <Route exact path="/termos-de-uso" component={TermsOfUse} />
          <Route exact path="/contato" component={Contact} />
          <Route exact path="/duvidas-frequentes" component={FAQ} />
          <Route exact path="/sso/:data" component={SSO} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
