// Dependencias

import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

// Componentes

import Header from '../Header'
import NavBar from './NavBar'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../painel/Sidebar'

// Imagens

import cardModelImage from '../../assets/images/modelo-cartao.png'

import credtiCardPlusImage from '../../assets/images/credit-card-plus-icon.png';

import mastercardImage from '../../assets/images/card-mastercard.png'
import amexImage from '../../assets/images/card-amex.png'
import visaImage from '../../assets/images/card-visa.png'

export const ChangeCreditCardBrowser = (props) => { 
    let customerCards = []
    const clientData = Lockr.get('ClientData')

    const { cardSummary, pmCard } = clientData.customerSelectedCar.contractInfo

   // console.log('ChangeCreditCardMobile >>>>');
    //console.log(clientData.customerSelectedCar.contractInfo);
    //('ChangeCreditCardMobile <<<<');
    
    if (!!cardSummary && !!pmCard) {
        customerCards = [{ label: pmCard === 'mc' ? 'Cartão de Crédito Mastercard' : pmCard === 'visa' ? 'Cartão de Crédito Visa' : 'Cartão de Crédito Amex', cardNum: `**** **** **** ${cardSummary}`, cardImage: pmCard === 'mc' ? mastercardImage : pmCard === 'visa' ? visaImage : amexImage }];
    } else {
        customerCards = [{ label: pmCard, cardNum: `**** **** **** ${cardSummary}`, cardImage: credtiCardPlusImage }];
    }

    return(
        <Section>
            <Header background="#5A78FF">
                <NavBar background="#5A78FF" titleColor="#FFFFFF" burgerWhite backButton>Trocar Cartão</NavBar>
            </Header>
            <Container fluid>
                <Row>
                    <div className="loovi-fixed-side-column">
                        <Sidebar />
                    </div>
                    <div className="loovi-fluid-column">
                        <div className="loovi-descktop-padding">
                            <Row className="loovi-full">
                                <Col sm={4}>
                                    <h4>Trocar cartão</h4>
                                    <figure className="loovi-card"><img src={cardModelImage} alt="Cartão de Crédito"/></figure>
                                </Col>
                                <Col sm={8}>
                                <div className="loovi-list-cards">
                                <div className="loovi-list-cards__item d-flex align-items-center" key={customerCards[0].cardNum}>
                                    <img src={customerCards[0].cardImage} alt={customerCards[0].label}/>
                                    <span className="loovi-color-5">{customerCards[0].cardNum}</span>
                                </div>
                            </div>
                                    <a className="loovi-link" href="/pagamentos/adicionar-cartao">Adicionar um novo cartão</a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </Section>
    )
}

const Section = styled.section`

    position: relative;

    h4{

        margin: 0 0 30px 0 !important;
        text-align: left !important;
    }

    .loovi-descktop-padding{

        padding-right: 50px;
    }

    .loovi-list-cards{

        margin-top: 50px !important;

        &__item{

            cursor: pointer;
        }
    }
`
export default ChangeCreditCardBrowser
