import { api_auth, URLS } from '../helpers/Request';
import { generateDistanceAndVelocity } from '../helpers/Travels';

export default class TravelsService {
  static async getLastTravelsByContract(contract, amount, pageToken) {
    try {
      if (pageToken) {
        pageToken = `/${pageToken}`;
      } else {
        pageToken = '';
      }
  
      const request = await api_auth.get(`${URLS.VIAGENS}/por-contrato/com-coordenadas/paginadas/${contract}/${amount}${pageToken}`).json();
      request.Itens = request.Itens.filter(request => !request.Deletado);

      request.Itens.forEach(travel => {
        const { distance, maximumVelocity, mediumVelocity } = generateDistanceAndVelocity(travel.CoordenadasValidas);
        travel.totalDistance = distance;
        travel.maximumVelocity = maximumVelocity;
        travel.mediumVelocity = mediumVelocity;
        travel.selected = false;
        travel.firstCoord = {
          lat: travel.CoordenadasValidas[0]?.lat,
          lng: travel.CoordenadasValidas[0]?.long,
        }
        travel.lastCoord = {
          lat: travel.CoordenadasValidas[travel.CoordenadasValidas.length - 1]?.lat,
          lng: travel.CoordenadasValidas[travel.CoordenadasValidas.length - 1]?.long,
        }
      });

      return request;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  static async deleteTravel(travelId) {
    try {
      await api_auth.delete(`${URLS.VIAGENS}/por-id/${travelId}`);
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  static async getCurrentPosition(contract) {
    try {
      const data = await api_auth.get(`${URLS.LOCALIZACAO}/${contract}`).json();
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}