// Dependencias

import React from 'react'
import styled from 'styled-components'

// Components
import Feedback from '../../components/painel/Feedback'
import { MobileView, BrowserView } from 'react-device-detect'
import { AdditionalServicesMobile } from './AdditionalServicesMobile'
import { AdditionalServicesBrowser } from './AdditionalServicesBrowser'

export class AdditionalServices extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            step: 1,
            
            customerCars: [], 
            customerInfo: {},
            customerSelectedCar: {},

            customerReboque: '',
            customerChaveiro: '',
            customerCarroReserva: '',
            looviTerms: ''
        }
    }

    proceed = () => {

        this.setState({
            step: 2
        })
    }

    handleChange = input => event => {

        this.setState({[input]: event.target.value})
    }

    handleSelectChange = selectedOption => {

        this.setState(
            { customerSelectedCar: selectedOption } 
        );
    };

    render(){

        const {
            step,
            customerCars, 
            customerSelectedCar,
            customerReboque,
            customerChaveiro,
            customerCarroReserva,
            looviTerms} = this.state

        const values = {
            step,
            customerCars, 
            customerSelectedCar,
            customerReboque,
            customerChaveiro,
            customerCarroReserva,
            looviTerms}
            
        switch (step) {
            case 2:
                
                return(

                    <Feedback feedbackTitle="Serviços Adicionais" 
                        alertTitle="Sua solicitação foi enviada com sucesso!"
                        contentTitle="Protocolo 1234567890">
                        <p>O Pedido foi realizado com sucesso. A ativação do serviço será efetivada em até 48h.</p>
                    </Feedback>
                )
            default:

                return(
                    <Section>
                        <MobileView>
                            <AdditionalServicesMobile 
                                values={values}
                                handleSelectChange={this.handleSelectChange}
                                handleChange={this.handleChange}
                                proceed={this.proceed} />
                        </MobileView> 
                        <BrowserView>
                            <AdditionalServicesBrowser 
                                values={values}
                                handleSelectChange={this.handleSelectChange}
                                handleChange={this.handleChange}
                                proceed={this.proceed} />
                        </BrowserView>   
                    </Section>
                )
        }
    }
}

const Section = styled.section`

    position: relative;
`

export default AdditionalServices