import React from "react";

import "./style.css";

export function LoaderInline({ color = "#5B74FF", size = 20 }) {
  return (
    <div className="c_loader_inline">
      <div
        className="c_loader_inline__loader"
        style={{
          borderTopColor: color,
          borderRightColor: color,
          width: `${size}px`,
          height: `${size}px`,
        }}
      />
    </div>
  );
}
