import React from "react";
import { version } from "../../../package.json";

import "./style.css";

export default function Version({ center = false, color = "white" }) {
  return (
    <div className="c_version" data-center={center} data-color={color}>
      v{version}
    </div>
  );
}