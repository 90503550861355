// Dependencias

import React, { useState } from 'react'
import styled from 'styled-components'
import useForm from 'react-hook-form'

// Componentes
import Header from '../Header'
import Body from '../Body'
import { Container, Row, Col } from 'react-bootstrap'
import LoaderAnimation from '../LoaderAnimation'

// Imagens
import looviIconImage from '../../assets/images/loovi-logo-icon.png'
import { api_keyed, URLS } from '../../helpers/Request';
import Version from '../Version';


const Form = (props) => {

    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)

    const onSubmit = ({ customerCPF, customerCNPJ }) => {
        const cpf = customerCPF.replace(/\./gi, '').replace(/-/gi, '');

        if (cpf.length !== 11 && cpf.length !== 14) {
            alert('CPF ou CNPJ inválidos!')
            return;
        }

        setLoading(true);

        api_keyed.get(`${URLS.AUTENTICACAO}/cliente/${cpf}`).json().then(data => {
            setLoading(false)
            props.proceed({ ...data, cpf })
        })
        .catch(err => {
            setLoading(false)
            alert('CPF ou CNPJ inválidos!');
        }) 

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LoaderAnimation show={isLoading} />
            <label className="placeholder-field-white">
                <span className="loovi-color-0">Digite seu CPF ou CNPJ</span>
                <input
                    name="customerCPF"
                    ref={register({ required: 'Preencha o campo acima' })}
                    onChange={props.handleChange}
                    className={errors.customerCPF ? 'loovi-field loovi-field-error' : 'loovi-field'}
                    type="text" />
                {errors.customerCPF && <span className="loovi-msg-error">{errors.customerCPF.message}</span>}
            </label>
            <input className="loovi-button" type="submit" value="Avançar" />
        </form>
    )
}

export class FormValidacaoCPF extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            customerCPF: ''
        }
    }

    componentDidMount() {

        document.body.style.backgroundColor = '#5A78FF'
    }

   proceed = (data) => {

        this.props.nextStep(data)
    }

    handleChange = event => {

        const target = event.target;
        const name = target.name
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [name]: value })
    }

    render() {

        return (
            <Section>
                <Header background="#5A78FF">
                    <figure className="loovi-icon"><img src={looviIconImage} alt="Loovi" /></figure>
                    <span className="loovi-spacer"></span>
                    <span className="loovi-spacer"></span>
                </Header>
                <Container >
                    <Body>
                        <Row>
                            <Col sm={12} md={{ span: 6, offset: 3 }}>
                                <h1 className="loovi-align">Olá, digite o seu CPF ou CNPJ</h1>
                                <p className="loovi-align">Tem que ser o mesmo que você utilizou no cadastrado de compra.</p>
                                <div className="loovi-spacer" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={{ span: 4, offset: 4 }}>
                                <Form
                                    handleChange={this.handleChange}
                                    proceed={this.proceed} />
                <Version center />
                            </Col>
                        </Row>
                    </Body>
                </Container>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;
    color: #FFFFFF;

    .loovi-icon{

        padding-top: 30px;
    }

    label{

        margin: 0;
    }

    .loovi-field{

        background: transparent;
        border: solid 2px #FFFFFF;
        color: #FFFFFF;
    }


    .loovi-button{

        background: #FFFFFF;
        color: #393043;
    }

    h1{
        font-size: 36px !important;
        color: #FFFFFF;
        letter-spacing: -1.29px;
        line-height: 43px;
    }
`

export default FormValidacaoCPF
