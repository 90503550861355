// (00) 0 0000-0000
export const phone = (str) => {
  if (!str) return str
  let phone = str.replace(/[() \-/]/g, '')

  if (phone.length <= 2) return phone.slice(0, 2);
  else if (phone.length <= 6) return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}`
  else if (phone.length <= 10) return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6, 11)}`
  return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7, 11)}`
}

// 00.000-000
export const cep = (str) => {
  if (!str) return str
  let cep = str.replace(/[.\-/]/g, '');

  return `${cep.slice(0, 2)}.${cep.slice(2, 5)}-${cep.slice(5, 8)}`
}

// 00000-000
export const cep9 = (str) => {
  if (!str) return str
  let cep = str.replace(/[.\-/]/g, '');

  if (cep.length <= 5) return cep.slice(0, 5);
  return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`
}

// 000.000.000-00
export const cpf = (str) => {
  if (!str) return str
  let cpf = str.replace(/[.\-/]/g, '');

  if (cpf.length <= 3) return cpf.slice(0, 3);
  else if (cpf.length <= 6) return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}`;
  else if (cpf.length <= 9) return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}`;
  return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`
}

// 25/06/2022 14:30
export const dateAndTime = (str) => {
  if (!str) return str
  let time = str.replace(/[.\-/]/g, '');

  if (time.length <= 2) return time.slice(0, 2)
  else if (time.length <= 4) return `${time.slice(0, 2)}/${time.slice(2, 4)}`
  else if (time.length <= 8) return `${time.slice(0, 2)}/${time.slice(2, 4)}/${time.slice(4, 8)}`
  else if (time.length <= 10) return `${time.slice(0, 2)}/${time.slice(2, 4)}/${time.slice(4, 8)} ${time.slice(8, 10)}`

  return `${time.slice(0, 2)}/${time.slice(2, 4)}/${time.slice(4, 8)} ${time.slice(8, 10)}:${time.slice(10, 12)}`
}

// 00.000.000/0001-00
export const cnpj = (str) => {
  if (!str) return str
  let cnpj = str.replace(/[.\-/]/g, '');

  if (cnpj.length <= 2) return cnpj.slice(0, 2);
  else if (cnpj.length <= 5) return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}`;
  else if (cnpj.length <= 8) return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}`;
  else if (cnpj.length <= 12) return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}`;
  return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
}

// R$ 000,00
export const real = (value) => {
  if (!value) {
    if (value === 0) return `R$ 0,00`
    return value
  }
  return `R$ ${value.toFixed(2).toString().replace('.', ',')}`;
}

// Placa brasileira ou mercosul:
// AAA9999
// AAA9A99
export function plate(str) {
  if (!str) return str
  let plateString = str.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

  return `${plateString.substr(0,3).replace(/[0-9]/g, '')}${plateString.substr(3,1).replace(/[a-zA-Z]/g, '')}${plateString.substr(4,1)}${plateString.substr(5,2).replace(/[a-zA-Z]/g, '')}`;
}