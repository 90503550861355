import { api_keyed, URLS } from "../helpers/Request";

export const PutAuxiliaryDocuments = async (
  data,
  caso,
  motivoCaso,
  tipoId,
  formato
) => {
  try {
    const response = await api_keyed.put(
      `${URLS.DOCUMENTOS_AUXILIARES}/upload/${caso}/${motivoCaso}/${tipoId}/${formato}`,
      {
        body: data,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();

    console.log('Success:', responseData);
    return responseData;
  } catch (e) {
    console.error("Error uploading document:", e);
    return false;
  }
};
