// Dependencias

import React from 'react'
import styled from 'styled-components'

// Componentes

import Header from '../Header'
import Body from '../Body'
import NavBar from './NavBar'
import LooviSelect from '../styles/LooviSelect'
import { Container, Row, Col } from 'react-bootstrap'
import FormCreditCard from './FormCreditCard'

// Imagens

import cardModelImage from '../../assets/images/modelo-cartao.png'

export const AddCreditCardMobile = (props) => {
    return(
        <Section>
            <Header background="#5A78FF">
                <NavBar background="#5A78FF" titleColor="#FFFFFF" backButton burgerWhite >Trocar Cartão</NavBar>
                <Container className="loovi-align-left">
                    <Row>
                        <Col sm={12} md={{span: 6, offset:3}}>
                            <p className="loovi-center loovi-bold">Selecione o veículo</p>
                            <LooviSelect
                                classNamePrefix="react-select"
                                value={props.values.customerSelectedCar}
                                onChange={props.handleSelectChange}
                                options={props.values.customerCars} />
                            <span className="loovi-spacer"></span>
                            <h3 className="loovi-header-title loovi-color-0">Cartão Atual</h3>
                            <figure className="loovi-card"><img src={cardModelImage} alt="Cartão de Crédito"/></figure>
                        </Col>
                    </Row>
                </Container>
                <div className="loovi-spacer"></div>
                <div className="loovi-spacer"></div>
            </Header>
            <Container>
                <Body>
                    <Row>
                        <Col sm={12} md={{span: 6, offset:3}}>
                            <h4 className="loovi-uppercase">Novo Cartão</h4>
                            <div className="loovi-spacer"></div>
                            <FormCreditCard
                                isAddCreditCard
                                values={props.values}
                                handleSelectChange={props.handleSelectChange}
                                isChangeCreditCard={true}
                                //handleChange={props.handleChange}
                                proceed={props.proceed}/>
                        </Col>
                    </Row>
                </Body>
            </Container>
        </Section>
    )
}

const Section = styled.section`
    position: relative;
    font-size:15px;

    h4{

        font-size: 12px;
        color: #9FA5B3;
        text-align: center;
        line-height: 21px;
        margin-top: 100px;
        letter-spacing: 1.8px;
    }

    .loovi-info-data{

        p{

            margin: 0;
        }
    }

    .loovi-payment-config{

        background: #EFF2F7;
    }

    .loovi-align-left{

        text-align: left !important;
    }

    .loovi-card{

        img{

            max-width: 100% !important;
            margin: 0 0 -150px 0;
        }
    }

    .loovi-radio{

        opacity: 0.9;
        font-size: 14px;
        color: #899299;
        letter-spacing: -0.5px;
        line-height: 26px;
    }

    .checkmark{

        background: #EFF2F7 !important;
    }
`

export default AddCreditCardMobile
