import React, { useState } from "react";
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button, Container, Alert, Spinner } from "react-bootstrap";

import { NavBar } from "./components/NavBar";
import Footer from "./components/Footer";

import backgroundImage from './assets/images/contato-background.png';

import ReCAPTCHA from "react-google-recaptcha";

import EmailService from "./services/EmailService";

export const Contact = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  function onChangeInput(e) {
    const name = e.target.name;
    const value = e.target.value;

    setSuccess(null);
    setError(null);

    setForm(prev => ({ ...prev, [name]: value }))
  }

  function isFormEmpty() {
    return form.email.length === 0 || form.name.length === 0 || form.phone.length === 0 || form.message.length === 0;
  }

  async function onSendEmail() {
    const template = `<table width="100%">
  <tr>
    <td>
      <h1 style="font-family: sans-serif;">Um cliente Loovi enviou uma mensagem:</h1>
      <b style="font-family: sans-serif;">Nome:</b><span style="font-family: sans-serif;">${form.name}</span><br />
      <b style="font-family: sans-serif;">E-mail:</b><span style="font-family: sans-serif;">${form.email}</span><br />
      <b style="font-family: sans-serif;">Telefone:</b><span style="font-family: sans-serif;">${form.phone}</span><br />
      <b style="font-family: sans-serif;">Mensagem:</b><br />
      <p style="font-family: sans-serif;">${form.message}</p>
    </td>
  </tr>
</table>`


    if (!isFormEmpty()) {
      try {
        setIsLoading(true);
        const res = await EmailService.sendEmail('Contato via Site', template);
        if (res) {
          setSuccess('Mensagem enviada com sucesso!');
          setIsLoading(false);
        }
      } catch (error) {
        setError('Erro ao enviar! Tente novamente!');
        setIsLoading(false);
      }
    } else {
      return setError('Preencha todos os campos!');
    }

  }

  return (
    <ContainerContact>
      <NavBar />
      <ContentContainer background={backgroundImage}>
        <Container>
          <Row  >
            <Column >
              <InfoContainer>

                <InfoTitle>FALE CONOSCO</InfoTitle>
                <InfoSubtitle>Vamos <br /> conversar?</InfoSubtitle>
                <InfoDescription>Estamos por aqui 24 horas por dia.</InfoDescription>

                <BottomContainer>
                  <InfoBottom><FontAwesomeIcon flip="horizontal" icon={faCommentDots} size="lg" /><span>faleconosco@loovi.com.br</span></InfoBottom>
                  <InfoBottom><FontAwesomeIcon flip="horizontal" icon={faPhoneAlt} size="lg" /><span>0800 944 7000</span></InfoBottom>
                  <InfoBottom><FontAwesomeIcon flip="horizontal" icon={faMapMarkerAlt} size="lg" /><span>Rua Queluzita, 34 / 18o andar - União<br /> CEP: 31920-011 - Belo Horizonte, MG</span></InfoBottom>
                </BottomContainer>

              </InfoContainer>
            </Column>
            <Column>
              <FormContainer >
                <Form>
                  <FormTitle>Envie sua mensagem</FormTitle>
                  <InputItem onChange={onChangeInput} value={form.name} name="name" placeholder="Nome" required />
                  <InputItem onChange={onChangeInput} value={form.email} name="email" type="email" placeholder="E-mail" required />
                  <InputItem onChange={onChangeInput} value={form.phone} name="phone" placeholder="Telefone (DDD)" required />
                  <TextArea onChange={onChangeInput} value={form.message} name="message" placeholder="Mensagem" required />

                  <ReCAPTCHAContainer>
                    <ReCaptcha
                      render="explicit"
                      sitekey="6LcPbakZAAAAAJq9TO-48cnMm-kfbNKMcHYs9LJo"
                      onChange={onChange}
                    />
                  </ReCAPTCHAContainer>

                  <ButtonContainer>
                    <SubmitButton onClick={onSendEmail} size="lg">
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                          'ENVIAR'
                        )}
                    </SubmitButton>
                  </ButtonContainer>
                  {error && <Alert style={{ marginTop: '25px' }} variant="danger" >{error}</Alert>}
                  {success && <Alert style={{ marginTop: '25px' }} variant="success" >{success}</Alert>}
                </Form>
              </FormContainer>
            </Column>
          </Row>
        </Container>

      </ContentContainer>
      <Footer />
    </ContainerContact>
  );
};

const ContainerContact = styled.div`
    overflow: hidden;
  `;

const ContentContainer = styled.div`
  background: url(${props => props.background});
  background-repeat: repeat-y;
  background-position-x: center;
  width: 100%;
  position: relative;
  padding-top: 170px;
  padding-bottom: 140px;

  @media only screen and (min-width: 1540px) {
    background-size: cover;
  }
`;


const InfoContainer = styled.div`
      display: flex;
      flex-direction: column;
    `;

const InfoTitle = styled.span`
      color: #fff;
      font-size: 15px;
      letter-spacing: 2px;
    `;


const InfoSubtitle = styled.h1`
      color: #fff;
      margin-top: 30px;
    `;

const InfoDescription = styled.span`
      color: #fff;
      font-size: 18px;
      margin-top: 20px;
    `;

const BottomContainer = styled.div`
      margin-top: 100px;
    `;

const InfoBottom = styled.span`
      color: #fff;
      font-size: 18px;
      margin-top: 20px;
      display: flex;
      align-items: center;
    
  > span {
        padding-left: 10px;
    }
  `;


const FormContainer = styled.div`
    background-color: tomato;
    display: flex;
  `;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const InputItem = styled.input`
  padding: 7px;
  font-size: 15px;
  width: 70%;
  margin-top: 10px;
  border: 1px solid #d6d6d6;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 17px;
  width: 70%;
  margin-top: 10px;
`;

const FormTitle = styled.h1`
  font-size: 33px;
  width: 70%;
`;

const ButtonContainer = styled.div`
  width: 70%;
`;

const SubmitButton = styled(Button)`
  padding: 15px 80px;
  font-size: 15px;
  background-color: #57D9A3;
  border: none;
  color: black;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    color: #fff;
    background-color: #46d69a;
  }
`;

const Column = styled(Col)`
  padding-bottom: 60px;
`;

const ReCAPTCHAContainer = styled.div`
  width: 70%;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ReCaptcha = styled(ReCAPTCHA)`
    @media only screen and (max-width: 991px) {
      transform: scale(0.70);
      transform-origin:0 0;
      transform-origin:0 0;
      -webkit-transform-origin:0 0;
    }


`;

var currentLocation = window.location.pathname;
if (currentLocation === '/contato') {
  document.body.style.padding = '0'
}
