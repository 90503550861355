import Select from 'react-select'
import styled from 'styled-components'

export const LooviSelect = styled(Select)`
.react-select__control{

    border-color: #EFF2F7;
    border-width: 2px;

    input{

        height: 46px !important;
    }

    &--is-focused, &--menu-is-open{

        border-color: #57D9A3 !important;
        box-shadow: none !important;
    }
} 

.react-select__indicator-separator{

    background: none;
}

.react-select__indicator{

    color: #979797;
}

.react-select__single-value, .react-select__option{

    color: #899299; 
    font-size: 15px;
    text-align: left;
}

.react-select__option--is-selected{

    background: #57D9A3;
    color: #FFFFFF;
}

.react-select__value-container{

    padding-top: 0;
    padding-bottom: 0;
}

.react-select__value-container div{
    
    margin: 0 !important;
    padding: 0 !important;
}
`

export default LooviSelect