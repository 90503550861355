// Dependencias
import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

// Componentes

import Header from '../Header'
import Body from '../Body'
import NavBar from './NavBar'
import { Container, Row, Col } from 'react-bootstrap'
import LooviSelect from '../styles/LooviSelect'

// Imagens

import cardModelImage from '../../assets/images/modelo-cartao.png'

import credtiCardPlusImage from '../../assets/images/credit-card-plus-icon.png';

import mastercardImage from '../../assets/images/card-mastercard.png'
import visaImage from '../../assets/images/card-visa.png'

export const ChangeCreditCardMobile = (props) => {
    let customerCards = []
    const clientData = Lockr.get('ClientData')

    const { cardSummary, pmCard } = clientData.customerSelectedCar.contractInfo

    //console.log('ChangeCreditCardMobile >>>>');
    //console.log(clientData.customerSelectedCar.contractInfo);
    //console.log('ChangeCreditCardMobile <<<<');

    if (!!cardSummary && !!pmCard) {
        customerCards = [{ label: pmCard === 'mc' ? 'Cartão de Crédito Mastercard' : pmCard === 'visa' ? 'Cartão de Crédito Visa' : 'Cartão de Crédito Amex', cardNum: `**** **** **** ${cardSummary}`, cardImage: pmCard === 'mc' ? mastercardImage : visaImage }];
    } else {
        customerCards = [{ label: pmCard, cardNum: `**** **** **** ${cardSummary}`, cardImage: credtiCardPlusImage }];
    }

    return(
        <Section>
            <Header background="#5A78FF">
                <NavBar background="#5A78FF" titleColor="#FFFFFF" backButton burgerWhite >Trocar Cartão</NavBar>
                <Container className="loovi-align-left">
                    <Row>
                        <Col sm={12} md={{span: 6, offset:3}}>
                            <p className="loovi-center loovi-bold">Selecione o veículo</p>
                            <LooviSelect
                                classNamePrefix="react-select"
                                value={props.customerSelectedCar}
                                onChange={props.handleSelectChange}
                                options={props.customerCars} />
                            <span className="loovi-spacer"></span>
                            <h3 className="loovi-header-title loovi-color-0">Trocar cartão</h3>
                            <figure className="loovi-card"><img src={cardModelImage} alt="Cartão de Crédito"/></figure>
                        </Col>
                    </Row>
                </Container>
                <div className="loovi-spacer"></div>
                <div className="loovi-spacer"></div>
            </Header>
            <Container fluid className="loovi-full">
                <Body>
                    <Row className="loovi-full">
                        <Col className="loovi-full" sm={12} md={{span: 6, offset:3}}>
                            <h4>Trocar cartão</h4>
                            <div className="loovi-list-cards">
                                <div className="loovi-list-cards__item d-flex align-items-center" key={customerCards[0].cardNum}>
                                    <img src={customerCards[0].cardImage} alt={customerCards[0].label}/>
                                    <span className="loovi-color-5">{customerCards[0].cardNum}</span>
                                </div>
                            </div>
                            <a className="loovi-link" href="/pagamentos/adicionar-cartao">Adicionar um novo cartão</a>
                        </Col>
                    </Row>
                </Body>
            </Container>
        </Section>
    )
}

const Section = styled.section`

    position: relative;
`
export default ChangeCreditCardMobile
