// Dependencias

import React from "react";
import styled from "styled-components";
import Lockr from "lockr";
import { authHandler } from "../../helpers/auth.js";

// Components

import Header from "../Header";
import NavBar from "../painel/NavBar";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Contracts from "./Contracts";
import { BrowserView, MobileView } from "react-device-detect";
import Sidebar from "../painel/Sidebar";
import * as masks from "../../helpers/masks";

export const Plano = (props) => {
  let contractLink, contractServiceLink, idIndicacao, services;

  if (props.customerSelectedCar && props.customerSelectedCar.contractInfo) {
    const { dflContractPDF, especificContractPDF, idIndc, salesContractItems } =
      props.customerSelectedCar.contractInfo;
    contractLink = especificContractPDF;
    contractServiceLink = dflContractPDF;
    idIndicacao = idIndc;
    services = salesContractItems;
  }

  const clientData = Lockr.get("ClientData");
  const nCtrId = clientData.customerSelectedCar.contractInfo.nCtrId;
  const chave_natural = nCtrId;
  const urlapolice = `https://pdf-clientes-loovi-lti.s3.amazonaws.com/${chave_natural}/emissao/${chave_natural}.pdf`;

  // console.log(chave_natural)

  let discount = 0;
  if (
    props.customerSelectedCar &&
    props.customerSelectedCar.contractDiscounts
  ) {
    discount = props.customerSelectedCar.contractDiscounts.reduce(
      (acc, discounts) => {
        if (!discounts.indebit) acc += 1;
        return acc;
      },
      0,
    );
    if (discount > 5) discount = 5;
  }

  return (
    <div className="loovi-box loovi-shadow">
      <h4 className="loovi-color-2">{props.customerSelectedCar.label}</h4>
      <div className="loovi-box-body">
        <div className="loovi-list">
          <div className="loovi-list__item">
            {services &&
              services.map((service) => {
                if (service.itemCode === "SRV_ADESAO") {
                  return <></>;
                } else if (service.itemCode === "SRV_GARANT") {
                  return <></>;
                }

                return (
                  <>
                    <div className="d-flex justify-content-between">
                      <h5 className="loovi-color-3">{service.itemName}</h5>

                      {service.unitPrice && (
                        <div className="loovi-list__value">
                          <p>{masks.real(service.unitPrice)}</p>
                        </div>
                      )}
                    </div>

                    {service.subItems != null && service.subItems.length > 0 ? (
                      <ul>
                        {service.subItems.map((subItem) => (
                          <li>
                            <p>{subItem.itemName}</p>
                            {subItem.subItemsCategory.length > 0 ? (
                              <ul>
                                {subItem.subItemsCategory.map(
                                  (subItemCategory) => (
                                    <li>
                                      <div className="d-flex justify-content-between">
                                        <p>{subItemCategory.itemName}</p>
                                        {subItemCategory.price ? (
                                          <div className="loovi-list__value">
                                            <p>
                                              {masks.real(
                                                subItemCategory.price,
                                              )}
                                            </p>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </li>
                                  ),
                                )}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
          </div>

          <div className="loovi-list__item">
            <h5 className="loovi-color-3">Desconto de Indicação</h5>
            <div className="d-flex justify-content-between">
              <div className="loovi-list__label">
                <p>{discount} Indicados</p>
              </div>
              <div className="loovi-list__value">
                <p className="loovi-color-2">{discount * 10}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="loovi-box-body">
        <p>Código de indicação: {idIndicacao}</p>
      </div>
      <hr />
      <div className="loovi-box-body">
        {/*<p><button onClick={props.showContract} className="loovi-link">Contrato</button></p>*/}
        {
          <p>
            <a
              href={contractLink}
              className="loovi-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contrato
            </a>
          </p>
        }
        <p>
          <a
            href={contractServiceLink}
            className="loovi-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contrato de prestação de serviços
          </a>
        </p>
        <p>
          <a
            href={urlapolice}
            className="loovi-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apólice
          </a>
        </p>
      </div>
      <hr />
      {/*             <div className="loovi-box-body">
                <a href="/" className="loovi-link">Cancelar plano</a>
            </div> */}
    </div>
  );
};

export const ContratosGeral = () => {
  return (
    <div className="loovi-contratos">
      <h5>Contratos Geral:</h5>
      <p>
        <a
          href="https://contratos-clientes.s3.amazonaws.com/termos-de-uso-loovi.pdf"
          className="loovi-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Termos de Uso
        </a>
      </p>
      <p>
        <a
          href="https://contratos-clientes.s3.amazonaws.com/politica-de-privacidade-loovi.pdf"
          className="loovi-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </a>
      </p>
      {/*             <p><a href="/" className="loovi-link" target="_blank" rel="noopener noreferrer">Plano de Indicação</a></p> */}
    </div>
  );
};

export class Signature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerCars: [],
      customerInfo: {},
      customerSelectedCar: {},
      showContract: false,
    };
  }

  async componentDidMount() {
    if (authHandler.isLogged()) {
      this.setState(Lockr.get("ClientData"));
    }
  }

  showContract = () => {
    if (this.state.showContract) {
      this.setState({ showContract: false });
    } else {
      this.setState({ showContract: true });
    }
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ customerSelectedCar: selectedOption });
  };

  render() {
    const { customerCars, customerSelectedCar, showContract } = this.state;

    switch (showContract) {
      case true:
        return (
          <Contracts
            showContract={this.showContract}
            contract={customerSelectedCar}
          />
        );
      default:
        return (
          <Section>
            <Header background="#5A78FF">
              <NavBar background="none" backButton burgerWhite />
              <MobileView>
                <Container className="loovi-align-left">
                  <Row>
                    <Col sm={12} md={{ span: 6, offset: 3 }}>
                      <p className="loovi-center loovi-bold">
                        Selecione o veículo
                      </p>
                      <LooviSelect
                        classNamePrefix="react-select"
                        value={customerSelectedCar}
                        onChange={this.handleSelectChange}
                        options={customerCars}
                      />
                      <span className="loovi-spacer"></span>
                      <h3 className="loovi-header-title loovi-color-0">
                        Meu plano
                      </h3>
                    </Col>
                  </Row>
                </Container>
              </MobileView>
            </Header>
            <MobileView>
              <Container fluid className="loovi-padding loovi-content">
                <Row>
                  <Col>
                    <Plano
                      customerSelectedCar={customerSelectedCar}
                      showContract={this.showContract}
                    />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <ContratosGeral />
                  </Col>
                </Row>
              </Container>
            </MobileView>
            <BrowserView>
              <Container fluid>
                <Row>
                  <div className="loovi-fixed-side-column">
                    <Sidebar />
                  </div>
                  <div className="loovi-fluid-column">
                    <Col>
                      <div className="loovi-descktop-padding">
                        <Row>
                          <Col>
                            <h3 className="loovi-title">Meu Plano</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={8}>
                            <Plano
                              customerSelectedCar={customerSelectedCar}
                              showContract={this.showContract}
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <ContratosGeral />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </div>
                </Row>
              </Container>
            </BrowserView>
          </Section>
        );
    }
  }
}

const Section = styled.section`
  position: relative;

  hr {
    margin: 0;
  }

  .loovi-align-left {
    text-align: left !important;
  }

  .loovi-content {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 130px;
      background: #5a78ff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .loovi-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .loovi-box {
    background: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    color: #899299;
    position: relative;

    h4 {
      text-align: center;
      padding: 10px 0;
      font-size: 18px;
      margin-bottom: 0;
      border-bottom: solid 1px #d8d8d8;
    }

    p {
      margin: 2px 0;
      font-size: 16px;
    }
  }

  .loovi-box-body {
    padding: 20px;
  }

  .loovi-list {
    h5 {
      font-family: "BrownStd-Regular";
      font-size: 16px;
      margin: 0;
      letter-spacing: normal;
    }

    &__item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .loovi-contratos {
    margin-top: 40px;

    p {
      margin: 10px 0;
    }

    h5 {
      font-family: "BrownStd-Regular";
      font-size: 15px;
      color: #899299;
      letter-spacing: -0.32px;
      line-height: 21px;
    }
  }

  .loovi-descktop-padding {
    .loovi-contratos {
      margin: 0;
    }
  }
`;

export const LooviSelect = styled(Select)`
  .react-select__control {
    border-color: #eff2f7;
    border-width: 2px;

    input {
      height: 46px !important;
    }

    &--is-focused,
    &--menu-is-open {
      border-color: #57d9a3 !important;
      box-shadow: none !important;
    }
  }

  .react-select__indicator-separator {
    background: none;
  }

  .react-select__indicator {
    color: #979797;
  }

  .react-select__single-value,
  .react-select__option {
    color: #899299;
    font-size: 15px;
  }

  .react-select__option--is-selected {
    background: #57d9a3;
    color: #ffffff;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__value-container div {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export default Signature;
