import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import mitt from 'mitt';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/BrownStd-Bold.woff';
import './assets/fonts/BrownStd-Regular.woff';
import './assets/fonts/BrownStd-Light.woff';
import './assets/fonts/BrownStd-Bold.otf';
import './assets/fonts/BrownStd-Regular.otf';
import './assets/fonts/BrownStd-Light.otf';

function loadAdyen() {
  // Caso a pagina ainda não tenha carregado,
  // tenta de novo em 200ms
  if (!document.body) {
    setTimeout(loadAdyen, 200);
    return;
  }

  const adyenAssets = {
    production: {
      js: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.3/adyen.js',
      css: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.3/adyen.css',
    },
    staging: {
      js: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.6.3/adyen.js',
      css: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.6.3/adyen.css',
    },
    development: {
      js: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.6.3/adyen.js',
      css: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.6.3/adyen.css',
    }
  }

  // Criando elementos que serão apendados ao DOM
  const linkElement = document.createElement('link');
  const scriptElement = document.createElement('script');

  // Adicionando URL CSS
  linkElement.rel = 'stylesheet';
  linkElement.href = adyenAssets[process.env.REACT_APP_BASE_ENVIRONMENT].css;

  // Adicionando URL JS
  scriptElement.src = adyenAssets[process.env.REACT_APP_BASE_ENVIRONMENT].js;

  // Apendando elementos no DOM
  document.body.appendChild(linkElement);
  document.body.appendChild(scriptElement);
}

loadAdyen();

function registerEmitter() {
  window.emitter = mitt();
}

function fireTracker() {
  window._etmc.push(["setOrgId", "110005701"]);
  window._etmc.push(["trackPageView"]);
}

registerEmitter();

window.onpopstate = function (event) {
  if (event.state) {
    fireTracker();
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
