// Dependencias

import React from 'react'
import { Redirect } from 'react-router';

// Componentes
import FormCPFValidation from './components/painel/FormCPFValidation'
import ValidationCode from './components/painel/ValidationCode'
import FormValidationCode from './components/painel/FormValidationCode'
import { authHandler } from './helpers/auth';

export class Painel extends React.Component {

    constructor(props) {
        super(props);

        if (authHandler.isLogged()) {
          
          return;
        }

        this.state = {
            step: 1
        }

        this.nextStep = this.nextStep.bind(this)
    }


    componentDidMount() {
        document.body.style.backgroundColor = '#5A78FF'
    }

    nextStep = (data) => {

        //console.log(data)

        const { step } = this.state
        this.setState({
            step: step + 1,
            data
        })
    }

    prevStep = () => {

        const { step } = this.state
        this.setState({
            step: step - 1
        })
    }

    render() {

      
      //console.log(authHandler.getToken());
      
      if (authHandler.isLogged()) {
        return <Redirect to="/dashboard" />
      }

      const { step } = this.state;

        switch (step) {
            case 1:
                return (
                    <React.Fragment>
                        <FormCPFValidation
                            nextStep={this.nextStep} />
                    </React.Fragment>
                )
            case 2:
                return (
                    <React.Fragment>
                        <ValidationCode
                            nextStep={this.nextStep}
                            data={this.state.data}
                        />
                    </React.Fragment>
                )
            case 3:
                return (
                    <React.Fragment>
                        <FormValidationCode
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            data={this.state.data} />
                    </React.Fragment>
                )
            case 4:
                document.body.style.backgroundColor = '#FFFFFF'
                return (
                    <Redirect to="/dashboard" />
                )
            default:
                return (
                    <React.Fragment>
                        <FormCPFValidation
                            nextStep={this.nextStep} />
                    </React.Fragment>
                )
        }
    }
}