export function resizeImage(file, maxWidth = 1500, maxHeight = 1500) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const resizedFile = new File(
              [blob],
              `${file.name.split(".")[0]}.jpg`,
              { type: "image/jpeg" }
            );
            resolve(resizedFile);
          },
          "image/jpeg",
          0.7
        );
      };

      img.onerror = (error) => {
        reject(error);
      };
    };

    reader.readAsDataURL(file);
  });
}
