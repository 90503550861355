import { api_keyed, URLS } from '../helpers/Request';

export default class EmailService {
  static async sendEmail(Assunto, Corpo) {
    try {
        await api_keyed.post(`${URLS.EMAIL}/envia/fale-conosco`, { json: { Assunto: Assunto, Corpo: Corpo } });
        return true;
    } catch (e) {
        console.warn(e);
        return false;
    }
  }
}