// Dependencias

import React from 'react'
import styled from 'styled-components'

// Componentes

import Header from '../Header'
import NavBar from '../painel/NavBar'
import { Container, Row} from 'react-bootstrap'
import Sidebar from '../painel/Sidebar'
import { MobileView, BrowserView } from 'react-device-detect'

// Imagens

import checkIconImage from '../../assets/images/check-parabens.png'


export class Feedback extends React.Component{

    render(){

        return(

            <Section feedbackTitle={this.props.feedbackTitle} navTitle={this.props.navTitle} alertTitle={this.props.alertTitle} contentTitle={this.props.contentTitle} >
                <Header background="#EFF2F7" >
                    <NavBar />
                </Header>
                <MobileView>
                    <div className="loovi-sub-header loovi-center">
                        <Container>   
                            <h3 className="loovi-header-title loovi-color-4">{this.props.feedbackTitle}</h3>
                            <h4 className="loovi-subtitle">{this.props.alertTitle}</h4>
                            <figure className="loovi-check"><img src={checkIconImage} alt="Parabéns"/></figure>
                        </Container>
                    </div>
{/*                     <Container>
                        <Body>
                            <div className="loovi-center">
                                <h5 className="loovi-body-title">{this.props.contentTitle}</h5>
                                <div className="loovi-color-4">
                                    {this.props.children}
                                </div>
                                <span className="loovi-spacer"></span>
                                <a className="loovi-link" href="/">Voltar para a Área do cliente</a>
                            </div>
                        </Body>
                    </Container> */}
                </MobileView>
                <BrowserView>
                    <Container fluid>
                        <Row>
                            <div className="loovi-fixed-side-column">
                                <Sidebar />
                            </div>
                            <div className="loovi-fluid-column">
                                <div className="loovi-sub-header loovi-sub-header-browser loovi-center"> 
                                    <h3 className="loovi-header-title loovi-color-4">{this.props.feedbackTitle}</h3>
                                    <h4 className="loovi-subtitle">{this.props.alertTitle}</h4>
                                    <figure className="loovi-check"><img src={checkIconImage} alt="Parabéns"/></figure>
                                </div>
{/*                                 <div className="loovi-center">
                                    <h5 className="loovi-body-title">{this.props.contentTitle}</h5>
                                    <div className="loovi-color-4">
                                        {this.props.children}
                                    </div>
                                    <span className="loovi-spacer"></span>
                                    {this.props.backLink && <a className="loovi-link" href="/">Voltar para a Área do cliente</a>}
                                </div> */}
                            </div>
                        </Row>
                    </Container>
                </BrowserView>
            </Section>
        )
    }
}

const Section = styled.section`

    h4.loovi-subtitle{

        position: relative;
        font-family: 'BrownStd-Regular';
        font-size: 18px;
        color: #899299;
        letter-spacing: -0.64px;
        text-align: center;
        line-height: 26px;
    }

    .loovi-sub-header-browser{

        margin-bottom: 60px;
    }

    .loovi-fluid-column{

        position: relative;
        z-index: 0;

        &::before{

            content: '';
            display: block;
            width: 30px;
            height: 300px;
            background: #FFFFFF;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    h5.loovi-body-title{

        font-size: 21px;
        color: #5A78FF;
        letter-spacing: -0.75px;
        line-height: 26px;
    }

    p{

        font-size: 15px;
    }

    .loovi-check{

        img{

            width: 44px;
            height: auto;
        }
    }
`

export default Feedback