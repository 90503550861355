import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import Lockr from "lockr";

import { FireWebToCase } from "../../../services/SalesForceService";

const Form = (props) => {
  const { register, handleSubmit, errors, clearError } = useForm();
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");

  const phone = props.values.contactPhone;
  const onSubmit = async (data) => {
    try {
      const reason = "Suporte";
      const subject = "Suporte Rastreador";
      const description = `Telefone para contato: ${phone}, Caso: ${props.values.caseArea.value}, Sobre o ocorrido: ${props.values.description}`;

      const formData = {
        reason,
        subject,
        "00N6f00000Febwz": contractId,
        "00NN5000001Lr7C": clientId,
        description,
      };

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);

      props.values.setLoading(true);

      await FireWebToCase(JSON.parse(formDataJson));

      props.values.setLoading(false);
      props.proceed();
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      }
    }

    loadData();
  }, [props.nCtrId]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <label>
            <span>CASO</span>
            <select
              name="caseAreaCheck"
              ref={register({ required: true })}
              className="loovi-select"
              value={props.values.caseArea.value}
              onChange={(e) => {
                const val = e.target.value;
                props.handleSelectChange(
                  { value: val, label: val },
                  "caseArea",
                );
                if (props.values.caseArea.value !== "")
                  return clearError("caseAreaCheck");
              }}
            >
              {props.values.caseAreas.map((area, index) => (
                <option key={index} value={area.value}>
                  {area.label}
                </option>
              ))}
            </select>
            {errors.caseAreaCheck && (
              <span className="loovi-msg-error">Selecione um caso</span>
            )}
          </label>
          <label>
            <span className="text-Ivoce">Explique o ocorrido</span>
            <textarea
              placeholder=""
              name="descriptionCheck"
              value={props.values.description.value}
              onChange={(e) => props.handleChange(e)}
              ref={register({ required: true })}
              className={
                errors.anotherMotive
                  ? "loovi-field loovi-field-error"
                  : "loovi-field"
              }
            />
            {errors.descriptionCheck && (
              <span className="loovi-msg-error">
                Explique melhor o ocorrido
              </span>
            )}
          </label>
        </>
        <input type="submit" value="Enviar solicitação" />
      </form>
    </React.Fragment>
  );
};

export class FormDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      description: "",
      caseAreas: [
        { value: "", label: "-- Nenhum --" },
        {
          value: "Defeito",
          label: "Defeito",
        },
        { value: "Perda, Furto ou Roubo", label: "Perda, Furto ou Roubo" },
      ],
      caseArea: { value: "", label: "-- Nenhum --" },
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ caseArea: selectedOption });
  };

  render() {
    const { step, description, caseArea, caseAreas } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      description,
      caseAreas,
      caseArea,
      contactPhone,
      setLoading,
    };

    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }
`;
