// Dependencias

import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import * as masks from "../../helpers/masks";

export const RegisterMobile = ({ data }) => {
  console.log(data);

  return (
    <Section>
      <Container fluid>
        <Row>
          <Col>
            <h4 className="loovi-color-2">Dados Pessoais</h4>
            <div className="loovi-info-data loovi-color-5">
              <p>{data.name}</p>
              <p>CPF: {masks.cpf(data.cpf)}</p>
              <p>Fone: {masks.phone(data.phone)}</p>
              <p>Email: {data.email}</p>
            </div>
            <span className="loovi-spacer" />
            {data && data.address && (
              <>
                <h4 className="loovi-color-2">Endereço</h4>
                {data.address
                  .filter((a) => a.addressType === "Cobrança")
                  .map((address) => (
                    <div
                      key={address.addressType}
                      className="loovi-info-data loovi-color-5"
                    >
                      <p>
                        {address.street}, {address.streetNo}{" "}
                        {address.building ? ` - ${address.building}` : ""}
                      </p>
                      <p>
                        {address.block}, {address.city} - {address.state}
                      </p>
                      <p>CEP: {masks.cep(address.zipCode)}</p>
                    </div>
                  ))}
              </>
            )}
            <span className="loovi-spacer" />
            <p className="loovi-color-5 loovi-bold">
              Para alterar os seus dados, vá em “Solicitações”.
            </p>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  position: relative;
`;
