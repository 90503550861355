// Dependencias

import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

// Componentes

import Header from '../Header'
import NavBar from './NavBar'
import PaymentHistoryMobile from './PaymentHistoryMobile'
import PaymentHistoryBrowser from './PaymentHistoryBrowser'
import { MobileView, BrowserView } from "react-device-detect";
import { authHandler } from '../../helpers/auth.js'
import { getHistoricoDePagamento } from './services.js'

// Imagens

import creditCardImage from '../../assets/images/credit-card-icon.png'

export class PaymentsHistory extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            customerCars: [],
            customerInfo: {},
            customerSelectedCar: {},
            payments: [],
        }
    }

    async componentDidMount() { 
        let value;
        let clientData;
        
        if (authHandler.isLogged()) {
            if(this.props.nCtrId) {
                clientData = Lockr.get('ClientData');
                //console.log(clientData);
                value = clientData.customerCars.filter(contracts  => {
                    return contracts.contractInfo.nCtrId === this.props.nCtrId;
                });
                //console.log(value);
                //console.log(value[0].value);
    
                Lockr.set('ClientData', {
                    IdCustomerSelectedCar: value[0].value,
                    customerCars: clientData.customerCars,
                    customerSelectedCar: value[0],
                    customerInfo: clientData.customerInfo
                });
            }
            clientData = Lockr.get('ClientData')
            const payments = await getHistoricoDePagamento(clientData.customerSelectedCar.contractInfo.nCtrId)
            this.setState({
                ...clientData,
                payments,
            })
        }
    }

    handleSelectChange = async selectedOption  =>  {
        const payments = await getHistoricoDePagamento(selectedOption.contractInfo.nCtrId);

        this.setState(
            {...{customerSelectedCar: selectedOption},
                payments}
        );
    };

    render() {

        const {
            customerCars,
            customerSelectedCar,
            payments,
        } = this.state

        return (
            <Section>
                <Header background="#EFF2F7" >
                    <NavBar backButton blueArrow/>
                </Header>
                <MobileView> 
                    <PaymentHistoryMobile
                        customerCars={customerCars}
                        customerSelectedCar={customerSelectedCar}
                        handleSelectChange={this.handleSelectChange}
                        payments={payments}
                    />
                </MobileView>
                <BrowserView>
                    <PaymentHistoryBrowser
                        customerCars={customerCars}
                        customerSelectedCar={customerSelectedCar}
                        handleSelectChange={this.handleSelectChange}
                        payments={payments}
                    />
                </BrowserView>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;
    font-size:15px;

    .loovi-info-data{

        p{

            margin: 0;
        }
    }

    .loovi-payment-config{

        background: #EFF2F7;
    }

    .loovi-align-left{

        text-align: left !important;
    }

    .loovi-cartao-info{

        padding-left: 70px;
        background-image: url(${creditCardImage});
        background-repeat: no-repeat;
        background-size: 58px 39px;
    }

    .loovi-change-card{

        color: #FFFFFF;
        background: #57D9A3;
        border-radius: 5px;
        padding: 8px 25px;
        display: inline-block;
        margin: 0 auto;
        font-family: 'BrownStd-Regular';
        font-size: 16px;

        &:hover{

            background: #5A78FF;
        }

        img{

            width: 24px;
            height: 19px;
            margin-left: 5px;
        }
    }

    .loovi-list{

        h4{

            font-size: 12px;
            color: #9FA5B3;
            letter-spacing: 1.8px;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .loovi-list-item{

        padding: 15px 25px;
        color: #899299;
        border-bottom: solid 1px #D8D8D8;
    }

    .loovi-payment-link{

        color: #5A78FF;
        border-bottom: solid 2px #5A78FF;

        &:hover{

            color: #57D9A3;
            border-color: #57D9A3;
        }
    }

    .loovi-download{

        img{

            width: 16px;
            height: auto;
        }
    }
`
