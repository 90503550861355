import React from "react";
import Lockr from "lockr";
import { URLS, api, api_keyed } from "../helpers/Request";

export const useFetch = (url, options) => {
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        setResponse(json);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [options, url]);
  return { response, error };
};

export const FireWebToCase = async (data) => {
  const environment = process.env.REACT_APP_BASE_ENVIRONMENT;
  const clientData = Lockr.get("ClientData");

  await new Promise((resolve) => setTimeout(resolve, 2000));

  let form;
  let commonForm;

  if(data.reason === "Alteração de Vencimento"){
    commonForm = {
      encoding: "UTF-8",
      submit: "Enviar",
      debug: 1,
      subject: data.subject,
      "00N6f00000Febwz": data["00N6f00000Febwz"],
      reason: data.reason,
      "00NN5000001Lr7C": "Cliente",
      "00N6f00000Fqsdd": data["00N6f00000Fqsdd"],
      "00N6f00000Fqt5S": data["00N6f00000Fqt5S"],
      description: data.description,
    };
  }else if(data.reason === "Carro Reserva"){
    commonForm = {
      encoding: "UTF-8",
      submit: "Enviar",
      debug: 1,
      subject: data.subject,
      "00N6f00000Febwz": data["00N6f00000Febwz"],
      reason: data.reason,
      "00NN5000001Lr7C": "Cliente",
      "00NN5000001OOjl": data["00NN5000001OOjl"],
      "00N88000000ts1P": data["00N88000000ts1P"],
      description: data.description,
    };
  }else if(data.reason === "Troca de Veículo"){
    commonForm = {
      encoding: "UTF-8",
      submit: "Enviar",
      debug: 1,
      subject: data.subject,
      "00N6f00000Febwz": data["00N6f00000Febwz"],
      reason: data.reason,
      "00NN5000001Lr7C": "Cliente",
      "00N6f00000Febyl":data["00N6f00000Febyl"],
      description: data.description,
    };
  }else if(data.reason === "Alteração de Dados Cadastrais"){
    commonForm = {
      ...data,
      encoding: "UTF-8",
      submit: "Enviar",
      debug: 1,
      subject: data.subject,
      "00N6f00000Febwz": data["00N6f00000Febwz"],
      reason: data.reason,
      "00NN5000001Lr7C": "Cliente",
      description: data.description,
    };
  }else{
    commonForm = {
      encoding: "UTF-8",
      submit: "Enviar",
      debug: 1,
      subject: data.subject,
      "00N6f00000Febwz": data["00N6f00000Febwz"],
      reason: data.reason,
      "00NN5000001Lr7C": "Cliente",
      description: data.description,
    };
  }



  if (environment === "production") {
    form = {
      orgid: "00D4P0000010vdU",
      ...commonForm,
    };
  } else {
    form = {
      orgid: "00D2C0000008keR",
      ...commonForm,
    };
  }

  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  fetch(`${URLS.WEBTOCASE}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(form),
  })
    .then((res) => {
      if (res.status !== 200) throw res.statusText;
      console.log("WEB TO CASE ENVIADO COM SUCESSO!");
      //props.proceed();
    })
    .catch((err) => {
      console.log("ERRO AO ENVIAR WEB TO CASE.");
      console.log(err);
      alert("Ops! ocorreu um erro, tente novamente mais tarde.");
    });
};

export const GetFireWebToCase = async (ctrId) => {
  try {
    let response;
    response = await api_keyed.get(`${URLS.CONSULTAS}/${ctrId}`).json();
    return response;
  } catch (e) {
    console.error("Erro ao obter WEB TO CASE:", e);
    return false;
  }
};

export class SalesForceService {
  /* static async postCallNow(idLead) {
    if(idLead){
      try {
         const result = await api.post(`${URLS.SFTOKEN}/?username=integracao.crm@loovi.com.br.service&password=loovi@17280piegMcYEfpLS6xwGnKYkI4aK&grant_type=password&client_id=3MVG9er.T8KbeePSigh_V9Zf6_JAxTnritZmUrzLHVyU.inY59fJ.iwwx5crlK1Kv4.Vif6hctJsI8x.tWu6b%0A&client_secret=BD6D7C876C47C33BADF4925757FE48594B8579E840C28726CE969B4F49A38974`).json();

        if(result){
           if(result.access_token){
             const response = await api.post(`${URLS.POST_CALL}/call?IdLead=${idLead}` {
              headers: {
                 "authorization":"Bearer "+result.access_token
               }
             }).json();
           }
         }

         return response;
       } catch (e) {
         console.log(e);
         return false;
       }
     } else {
       return;
     }
   } */

  static async getCall(mGtrid) {
    try {
      return await api.get(
        `${URLS.SALES_FORCE}/consultas/casos_app_por_contrato/${mGtrid}`,
      );
    } catch (e) {
      console.warn(e.message);
      return e.message;
    }
  }
}