import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import Lockr from "lockr";

import {
  FireWebToCase,
  GetFireWebToCase,
} from "../../../services/SalesForceService";

import file_upload from "../../../assets/icons/file_upload.svg";
import check_icon from "../../../assets/icons/check.svg";
import car_icon from "../../../assets/icons/car_icon.svg";
import uber_icon from "../../../assets/icons/uber_icon.svg";
import check_blue from "../../../assets/icons/check_blue.svg";
import referencia_frontal from "../../../assets/icons/solicitacoes/referencia_frontal_car.png";
import photo_icon from "../../../assets/icons/photo_icon.svg";
import arrow_right from "../../../assets/icons/arrow_right.svg";
import camera_icon from "../../../assets/icons/camera_icon.svg";
import referencia_traseira from "../../../assets/icons/solicitacoes/referencia_traseira_car.png";
import gps_icon from "../../../assets/icons/gps_icon.svg";

import Webcam from "react-webcam";
import { PutAuxiliaryDocuments } from "../../../services/AuxiliaryDocuments";
import { delay } from "../../../helpers/timeout";

const Form = (props) => {
  const { register, handleSubmit, errors, clearError } = useForm();
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [clientCpf, setClientCpf] = useState("");

  const [reportCard, setReportCard] = useState("");
  const [reportCardType, setReportCardType] = useState("");

  const handleReportCardChange = (e) => {
    const file = e.target.files[0];
    const validTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (file && validTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setReportCard(base64String);
        setReportCardType(file.type);
        props.handleFile1Change(e);
      };
      reader.readAsDataURL(file);
    }
  };

  

  const [selectedOption, setSelectedOption] = useState("");
  const [isSelectedOption, setIsSelectedOption] = useState(false);

  const handleOptionChange = (event) => {
    setIsSelectedOption(true);
    setSelectedOption(event.target.value);
    props.handleChangetransportOption({
      target: {
        name: "transportOption",
        value: event.target.value,
      },
    });
  };

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file1Base64, setFile1Base64] = useState("");
  const [file2Base64, setFile2Base64] = useState("");
  const [file1Type, setFile1Type] = useState("");
  const [file2Type, setFile2Type] = useState("");

  const handleFile1Change = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFile1(fileUrl);
      props.handleFile1Change(fileUrl);

      const fileExtension = file.name.split(".").pop();
      setFile1Type(fileExtension);

      const img = new Image();
      img.src = fileUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const jpgDataUrl = canvas.toDataURL("image/jpeg");

        const base64String = jpgDataUrl.split(",")[1];
        setFile1Base64(base64String);

        setTimeout(() => {
          closeModalFile();
        }, 300);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", error);
      };
    }
  };

  const handleFile2Change = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFile2(fileUrl);
      props.handleFile2Change(fileUrl);

      const fileExtension = file.name.split(".").pop();
      setFile2Type(fileExtension);

      const img = new Image();
      img.src = fileUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const jpgDataUrl = canvas.toDataURL("image/jpeg");

        const base64String = jpgDataUrl.split(",")[1];
        setFile2Base64(base64String);

        setTimeout(() => {
          closeModalFile();
        }, 300);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", error);
      };
    }
  };

  const handleSetFileNull = (event, file) => {
    event.preventDefault();
    if (file === "1") {
      setFile1("");
    } else {
      setFile2("");
    }
  };

  const base64ToFileURL = (base64, filename) => {
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return URL.createObjectURL(blob);
  };
  

  const openModal = (file) => () => {
    if (file === "1") {
      setIsModalSelected1Open(true);
    } else {
      setIsModalSelected2Open(true);
    }
  };

  const closeModal = () => {
    setIsModalSelected1Open(false);
    setIsModalSelected2Open(false);
  };

  const [isModalSelected1Open, setIsModalSelected1Open] = useState(false);
  const [isModalSelected2Open, setIsModalSelected2Open] = useState(false);
  const [isModalFile1Open, setIsModalFile1Open] = useState(false);
  const [isModalFile2Open, setIsModalFile2Open] = useState(false);

  const openModalFile = (event, file) => {
    event.preventDefault();
    if (file === "1") {
      setIsModalFile1Open(true);
    } else {
      setIsModalFile2Open(true);
    }
  };

  const closeModalFile = () => {
    setIsModalFile1Open(false);
    setIsModalFile2Open(false);
  };

  const [isCameraFile1Open, setIsCameraFile1Open] = useState(false);
  const [isCameraFile2Open, setIsCameraFile2Open] = useState(false);
  const [isPhotoHorizontal, setIsPhotoHorizontal] = useState(false)
  const webcamRef = useRef(null);

  const videoConstraints = {
    facingMode: { exact: "environment" },
  };

  const openModalCamera = (event, file) => {
    event.preventDefault();
    if (file === "1") {
      setIsCameraFile1Open(true);
    } else {
      setIsCameraFile2Open(true);
    }
    closeModalFile();
  };

  const closeModalCamera = () => {
    setIsCameraFile1Open(false);
    setIsCameraFile2Open(false);
    closeModalFile();
  };

  function rotateBase64Image(base64Str) {
    return new Promise((resolve, reject) => {
        // Cria um elemento de imagem e um canvas
        const img = new Image();
        img.src = base64Str;

        img.onload = () => {
            // Configura o canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Define o tamanho do canvas para a imagem
            canvas.width = img.width;
            canvas.height = img.height;

            // Calcula o centro da imagem
            const centerX = img.width / 2;
            const centerY = img.height / 2;

            // Rotaciona o contexto
            ctx.translate(centerX, centerY);
            ctx.rotate(360 * Math.PI / 180);
            ctx.translate(-centerX, -centerY);

            // Desenha a imagem no canvas
            ctx.drawImage(img, 0, 0);

            // Obtém a nova base64 da imagem rotacionada
            const rotatedBase64 = canvas.toDataURL();

            // Resolve a promessa com a nova base64
            resolve(rotatedBase64);
        };

        img.onerror = (error) => {
            reject(error);
        };
    });
}

  const capture = async(event) => {
    event.preventDefault();
    setIsPhotoHorizontal(false)

    const imageSrc = webcamRef.current.getScreenshot();

    if(window.screen.orientation.type === 'landscape-primary'){
      
      const fileUrl = base64ToFileURL(
        imageSrc,
        isCameraFile1Open ? "file1.jpg" : "file2.jpg"
      );
      
      if (isCameraFile1Open) {
        setFile1( await rotateBase64Image(fileUrl));
      } else {
        setFile2(await rotateBase64Image(fileUrl));
      }

      setIsPhotoHorizontal(true)
    }else{
      const fileUrl = base64ToFileURL(
        imageSrc,
        isCameraFile1Open ? "file1.jpg" : "file2.jpg"
      );

      if (isCameraFile1Open) {
        setFile1(fileUrl);
      } else {
        setFile2(fileUrl);
      }
    }
  };

  const [location, setLocation] = useState(null);

  useEffect(() => {
    async function loadData() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`);
          props.handleLocationChange(location);
        });
      }
    }

    loadData();
  }, [location, props]);

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    props.handleLocationChange(e.target.value);
  };

  const phone = props.values.contactPhone;
  const onSubmit = async (data) => {
    try {
      props.values.setLoading(true);
      let CaseNumber;
      let formData;

      const reason = "Carro Reserva";
      const subject = "Carro Reserva";
      const descriptionA = `Telefone para contato: ${phone}, Motivo: ${
        props.values.reason.value
      }, Opção de transporte: ${
        props.values.transportOption === "carro_reserva"
          ? "Carro Reserva"
          : "Uber"
      }, Localização: ${location}`;
      const descriptionB = `Telefone para contato: ${phone}, Motivo: ${
        props.values.reason.value
      }, Opção de transporte: ${
        props.values.transportOption === "carro_reserva"
          ? "Carro Reserva"
          : "Uber"
      }, Localização: ${location}`;

      
      if (props.values.reason.value === "Furto" || props.values.reason.value === "Roubo") {
        formData = {
          reason,
          subject,
          "00N6f00000Febwz": contractId,
          "00NN5000001Lr7C": clientId,
          "00N88000000ts1P": "Furto ou Roubo",
          description: descriptionA,
        };
      } else if (props.values.reason.value === "Incêndio") {
        formData = {
          reason,
          subject,
          "00N6f00000Febwz": contractId,
          "00NN5000001Lr7C": clientId,
          "00N88000000ts1P": "Incêndio",
          description: descriptionB,
        };
      } else if (props.values.reason.value === "Colisão") {
        formData = {
          reason,
          subject,
          "00N6f00000Febwz": contractId,
          "00NN5000001Lr7C": clientId,
          "00N88000000ts1P": "Colisão",
          description: descriptionB,
        };
      }

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);

      await FireWebToCase(JSON.parse(formDataJson));

      await delay(10000);

      const response = await GetFireWebToCase(contractId);

      const filteredResponse = response.filter(
        (item) => item.Status === "New" && item.Reason === reason
      );

      if (filteredResponse.length > 0) {
        const selectedCase = filteredResponse[0];
        CaseNumber = selectedCase.CaseNumber;

        if (file1Base64.length > 0 && file2Base64.length > 0) {
          await PutAuxiliaryDocuments(
            file1Base64,
            CaseNumber,
            "carro-reserva",
            "dianteira-motorista",
            "jpg"
          );

          await PutAuxiliaryDocuments(
            file2Base64,
            CaseNumber,
            "carro-reserva",
            "traseira-passageiro",
            "jpg"
          );
        }

        if (reportCard.length > 0) {
          if (reportCardType === "application/pdf") {
            await PutAuxiliaryDocuments(
              reportCard,
              CaseNumber,
              "carro-reserva",
              "boletim",
              "pdf"
            );
          } else {
            await PutAuxiliaryDocuments(
              reportCard,
              CaseNumber,
              "carro-reserva",
              "boletim",
              "jpg"
            );
          }
        }
      }
      props.values.setLoading(false);
      props.proceed();
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientCpf(clientData.customerInfo.cpf);
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        setClientCpf(clientData.customerInfo.cpf);
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      }
    }

    loadData();
  }, [props.nCtrId]);



  
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <label>
            <span className="text-color-Ivoce">MOTIVO</span>
            <select
              name={`reason`}
              ref={register({ required: true })}
              className="loovi-select"
              value={props.values.reason.value}
              onChange={(e) => {
                const val = e.target.value;
                props.handleSelectReason({ value: val, label: val }, "reason");
                if (props.values.reason.value !== "")
                  return clearError(`reason`);
              }}
            >
              {props.values.reasons.map((area, idx) => (
                <option key={idx} value={area.value}>
                  {area.label}
                </option>
              ))}
            </select>
            {errors.reason && (
              <span className="loovi-msg-error">Selecione uma opção</span>
            )}
          </label>
          {(props.values.reason.value === "Furto" ||
            props.values.reason.value === "Roubo") && (
            <>
              <label>
                <span className="text-Ivoce">BOLETIM DE OCORRÊNCIA</span>
                <div className="form-group">
                  {reportCard.length === 0 || reportCard === undefined ? (
                    <div className="upload-file">
                      <p>Envie no formato PDF*</p> <img src={file_upload} />
                    </div>
                  ) : (
                    <div className="upload-file selected-file">
                      <p>Boletim</p> <img src={check_icon} />
                    </div>
                  )}
                  <input type="file" onChange={handleReportCardChange} />
                </div>
              </label>
              {reportCard.length > 0 && (
                <>
                  <div className="options-selected">
                    <h4>Opções de Transporte</h4>
                    <p>Como deseja utilizar o seu período de carro reserva?</p>
                    <>
                      <div className="option-selected">
                        <input
                          type="radio"
                          name="option-car"
                          value="carro_reserva"
                          checked={selectedOption === "carro_reserva"}
                          onChange={handleOptionChange}
                        />
                        <img src={car_icon} alt="Carro Reserva" />
                        <p>Carro Reserva</p>
                      </div>
                      <div className="option-selected">
                        <input
                          type="radio"
                          name="option-car"
                          value="uber"
                          checked={selectedOption === "uber"}
                          onChange={handleOptionChange}
                        />
                        <img src={uber_icon} alt="Uber" />
                        <p>Uber</p>
                      </div>
                    </>
                  </div>
                  {selectedOption === "carro_reserva" && (
                    <label>
                      <span className="text-Ivoce">ONDE ESTOU</span>
                      <input
                        type="text"
                        name="location"
                        ref={register({ required: true })}
                        value={location}
                        onChange={handleLocationChange}
                        className="loovi-map"
                        style={{
                          background: `url(${gps_icon}) no-repeat center right 10px #fff`,
                          paddingRight: "40px",
                        }}
                      />
                    </label>
                  )}
                </>
              )}
            </>
          )}
          {(props.values.reason.value === "Incêndio" ||
            props.values.reason.value === "Colisão") && (
            <>
              <div className="label">
                <span className="text-Ivoce">1ª FOTO DO VEÍCULO</span>
                <div className="form-group">
                  {file1.length === 0 || file1 === undefined ? (
                    <div className="upload-file" onClick={openModal("1")}>
                      <p>Anexe foto do veículo</p> <img src={file_upload} />
                    </div>
                  ) : (
                    <div className="upload-file selected-file">
                      <p>Foto do veículo</p> <img src={check_icon} />
                    </div>
                  )}
                </div>
              </div>
              <div className="label">
                <span className="text-Ivoce">2ª FOTO DO VEÍCULO</span>
                <div className="form-group">
                  {file2.length === 0 || file2 === undefined ? (
                    <div className="upload-file" onClick={openModal("2")}>
                      <p>Anexe foto do veículo</p> <img src={file_upload} />
                    </div>
                  ) : (
                    <div className="upload-file selected-file">
                      <p>Foto do veículo</p> <img src={check_icon} />
                    </div>
                  )}
                </div>
              </div>
              {file1.length > 0 && file2.length > 0 && (
                <>
                  <div className="options-selected">
                    <h4>Opções de Transporte</h4>
                    <p>Como deseja utilizar o seu período de carro reserva?</p>
                    <>
                      <div className="option-selected">
                        <input
                          type="radio"
                          name="option-car"
                          value="carro_reserva"
                          checked={selectedOption === "carro_reserva"}
                          onChange={handleOptionChange}
                        />
                        <img src={car_icon} alt="Carro Reserva" />
                        <p>Carro Reserva</p>
                      </div>
                      <div className="option-selected">
                        <input
                          type="radio"
                          name="option-car"
                          value="uber"
                          checked={selectedOption === "uber"}
                          onChange={handleOptionChange}
                        />
                        <img src={uber_icon} alt="Uber" />
                        <p>Uber</p>
                      </div>
                    </>
                  </div>
                  {selectedOption === "carro_reserva" && (
                    <label>
                      <span className="text-Ivoce">ONDE ESTOU</span>
                      <input
                        type="text"
                        name="location"
                        ref={register({ required: true })}
                        value={location}
                        onChange={handleLocationChange}
                        className="loovi-map"
                        style={{
                          background: `url(${gps_icon}) no-repeat center right 10px #fff`,
                          paddingRight: "40px",
                        }}
                      />
                    </label>
                  )}
                </>
              )}
            </>
          )}
          {(isModalSelected1Open || isModalSelected2Open) && (
            <div className="modal-overlay">
              <div className="modal-imgs">
                <h3 className="loovi-header-title loovi-color-grey-2">
                  EVIDÊNCIA DO SINISTRO
                </h3>
                {isModalSelected1Open ? (
                  <h4>Foto dianteira do veículo com lateral do motorista</h4>
                ) : (
                  <h4>Foto da traseira do veículo com lateral do passageiro</h4>
                )}
                <div className="infos-text">
                  <div className="info-text">
                    <img src={check_blue} />
                    <p>Veículo deve aparecer por completo.</p>
                  </div>
                  <div className="info-text">
                    <img src={check_blue} />
                    <p>Placa precisar estar visível.</p>
                  </div>
                </div>
                {isModalSelected1Open ? (
                  <div className="reference-img">
                    {file1.length === 0  ? (
                      <div
                        style={{
                          backgroundImage: `url(${referencia_frontal})`,
                          height: "260px",
                          backgroundSize: "contain",
                          padding: "0px",
                          width: '300px',
                          backgroundPosition: "center",
                          backgroundRepeat: 'no-repeat',
                          maxWidth: '300px'
                        }}
                      ></div>
                    ) : (
                      <ImagePhoto
                        style={{
                          backgroundImage: `url(${file1})`,
                          height: "260px",
                          backgroundSize: "contain",
                          padding: "0px",
                          width: '300px',
                          backgroundPosition: "center",
                          backgroundRepeat: 'no-repeat',
                          maxWidth: '300px'
                        }}
                      ></ImagePhoto>
                    )}
                  </div>
                ) : (
                  <div className="reference-img">
                    {file2.length === 0 ? (
                      <div
                        style={{
                          backgroundImage: `url(${referencia_traseira})`,
                          height: "260px",
                          backgroundSize: "contain",
                          padding: "0px",
                          width: '300px',
                          backgroundPosition: "center",
                          backgroundRepeat: 'no-repeat',
                          maxWidth: '300px'
                        }}
                      ></div>
                    ) : (
                      <ImagePhoto
                        style={{
                          backgroundImage: `url(${file2})`,
                          height: "260px",
                          backgroundSize: "contain",
                          padding: "0px",
                          width: '300px',
                          backgroundPosition: "center",
                          backgroundRepeat: 'no-repeat',
                          maxWidth: '300px'
                        }}
                      ></ImagePhoto>
                    )}
                  </div>
                )}
                {isModalSelected1Open ? (
                  <>
                    {file1.length > 0 ? (
                      <button
                        type="button"
                        className="btn-attach"
                        onClick={closeModal}
                      >
                        Anexar foto
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn-next"
                        onClick={(e) => openModalFile(e, "1")}
                      >
                        Continuar
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {file2.length > 0 ? (
                      <button
                        type="button"
                        className="btn-attach"
                        onClick={closeModal}
                      >
                        Anexar foto
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn-next"
                        onClick={(e) => openModalFile(e, "2")}
                      >
                        Continuar
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {(isModalFile1Open || isModalFile2Open) && (
            <div className="modal-file">
              <div className="modal-file-imgs">
                <div className="options-img">
                  <div className="selected-img">
                    <div className="content-upload">
                      <div className="upload-file-img">
                        <div className="upload-file-text">
                          <img src={photo_icon} /> <p>Anexar foto</p>
                        </div>
                        <img src={arrow_right} />
                      </div>
                      {isModalFile1Open ? (
                        <input type="file" onChange={handleFile1Change} accept=".jpg,.jpeg,.png" />
                      ) : (
                        <input type="file" onChange={handleFile2Change} accept=".jpg,.jpeg,.png"/>
                      )}
                    </div>
                    <div className="divider"> </div>
                    <div className="content-upload">
                      {isModalFile1Open ? (
                        <div
                          className="upload-file-img"
                          onClick={(e) => openModalCamera(e, "1")}
                        >
                          <div className="upload-file-text">
                            <img src={camera_icon} /> <p>Tirar foto</p>
                          </div>
                          <img src={arrow_right} />
                        </div>
                      ) : (
                        <div
                          className="upload-file-img"
                          onClick={(e) => openModalCamera(e, "2")}
                        >
                          <div className="upload-file-text">
                            <img src={camera_icon} /> <p>Tirar foto</p>
                          </div>
                          <img src={arrow_right} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
         {(isCameraFile1Open || isCameraFile2Open) && (
            <div className="map-modal">
              {(isCameraFile1Open && file1.length === 0) ||
              (isCameraFile2Open && file2.length === 0) ? (
                <div className="camera-container">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/jpg"
                    style={{ width: "100%", height: "100%" , objectFit:"cover", minWidth: "100vw" , minHeight: "100vh"}}
                  />
                  <ButtonCapture onClick={capture} />
                </div>
              ) : (
                <>
                  <ModalVerify isPhotoHorizontal={isPhotoHorizontal}>
                    <img src={ isCameraFile1Open ? file1: file2 } alt='' />
                    <div>
                        <p>Está nítido?</p>
                        <div>
                            <button onClick={(e) => handleSetFileNull(e, isCameraFile1Open ? "1": "2")}>Não</button>
                            <button onClick={closeModalCamera}>Sim</button>
                        </div>
                    </div>
                  </ModalVerify>
              </>
              )}
            </div>
          )}
        </>
        <input
          type="submit"
          value="Enviar Solicitação"
          disabled={!isSelectedOption}
        />
      </form>
    </React.Fragment>
  );
};

export class FormBackupCar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      reportCard: "",
      transportOption: "",
      reasons: [
        { value: "", label: "-- Nenhum --" },
        { value: "Furto", label: "Furto" },
        { value: "Roubo", label: "Roubo" },
        { value: "Incêndio", label: "Incêndio" },
        { value: "Colisão", label: "Colisão" },
      ],
      reason: { value: "", label: "-- Nenhum --" },
      file1: "",
      file2: "",
      location: "",
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleChangetransportOption = (event) => {
    this.setState({ transportOption: event.target.value });
  };

  handleReportCardChange = (event) => {
    this.setState({ file1: event.target.value });
  };

  handleFile1Change = (file) => {
    this.setState({ file1: file });
  };

  handleFile2Change = (file) => {
    this.setState({ file2: file });
  };

  handleLocationChange = (location) => {
    this.setState({ location: location });
  };

  handleSelectReason = (selectedOption) => {
    this.setState({ reason: selectedOption });
  };

  render() {
    const {
      step,
      description,
      reportCard,
      reasons,
      reason,
      transportOption,
      file1,
      file2,
      location,
    } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      description,
      reportCard,
      reasons,
      reason,
      file1,
      file2,
      location,
      transportOption,
      contactPhone,
      setLoading,
    };

    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleChange={this.handleChange}
          handleFile1Change={this.handleFile1Change}
          handleSelectReason={this.handleSelectReason}
          handleChangetransportOption={this.handleChangetransportOption}
          handleReportCardChange={this.handleReportCardChange}
          handleFile2Change={this.handleFile2Change}
          handleLocationChange={this.handleLocationChange}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }

  .form-group {
    height: auto;
    width: 100%;
  }

  .upload-file {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    height: 50px;
    border-radius: 10px;
    border: solid 2px #f2f3f8;
    width: 100%;
    padding: 16px 10px;
  }

  .upload-file p {
    color: #a1a4ab !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
  }

  .upload-file img {
    width: 18px;
    height: 18px;
  }

  .selected-file {
    border: none;
    background-color: #5a78ff;
    color: #ffffff;
    height: 34px;
    border-radius: 4px;
    width: 100%;
    padding: 8px 12px;
    font-size: 15px;
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
  }

  .selected-file p {
    color: #ffffff !important;
    font-weight: 400 !important;
  }

  .options-selected {
    background: #f2f2f2;
    width: 100%;
    flex-direction: column;
    display: flex;
    padding: 16px 12px;
    border-radius: 12px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .options-selected p {
    font-weight: 400px;
    font-size: 12px;
    color: #515151;
  }

  .options-selected h4 {
    color: #000000;
    font-size: 14px;
  }

  .option-selected {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5rem;
  }

  .option-selected img {
    width: 24px;
    height: 24px;
    margin-left: 0.5rem;
  }

  .option-selected p {
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    margin-left: 0.5rem;
  }

  form input[type="radio"] {
    width: 18px;
    height: 18px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
  }

  .map-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 4;
  }


  .modal-imgs, .map-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: auto;
    max-width: 500px;
  }

  .camera-container, .camera-container-verify {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: auto;
    max-width: 800px;
  }

  .camera-container{
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    .modal-imgs, .map-container, .camera-container {
      background-color: #fff;
        padding: 20px;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        height: 100vh;
    }

    .camera-container{
      padding: 0;
    }


    .camera-container-verify {
      height: 100vw !important;
      transform: rotate(90deg) !important;
      transform-origin: center !important;
      width: 100vh !important;
      position: fixed !important;
      max-width: none !important;
    }

    .modal-overlay, .map-modal {

    }

    .reference-img img {
      width: 100%;
      height: 198px;
    }

    .btn-next {
      width: 90% !important;
      height: 48px;
      position: absolute;
      bottom: 1rem;
      margin-top: 0 !important;
    }

    .btn-attach {
      width: 90% !important;
      height: 48px;
      position: absolute;
      bottom: 1rem;
      margin-top: 4rem;
    }

    .buttons-modal-map {
      display: flex;
      justify-content: space-evenly;
      width: 50%;
      flex-direction: column
    }

    .buttons-modal-camera {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      flex-direction: column
    }

    .modal-imgs h4 {
      text-align: left;
    }

    .modal-imgs h3 {
      margin-top: 4rem;
    }

    .upload-file-img {
      width: 85% !important
    }

    .buttons-modal-confirm p {
      margin-top: 0 !important
    }
  }

  @media screen and (min-width: 768px) {
    .modal-imgs, .map-container, .camera-container, .camera-container-verify {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .modal-imgs h4 {
      text-align: center;
    }

    .info-text {
      margin-left: 10%;
    }

    .options-img {
      width: 80%;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .modal-file-imgs {
      bottom: 24.5% !important
    }

    .upload-file-img {
      width: 80% !important
    }

  }

  .modal-imgs h3 {
    color: #A1A4AB
  }

  .modal-imgs h4 {
    color: #5A78FF;
    font-size: 20px;
    font-weight: 700;
  }

  .buttons-modal-map {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    flex-direction: row
  }

  .buttons-modal-camera {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row
  }

  .buttons-modal-confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.72);
    height: 140px;
  }

  .buttons-modal-confirm p {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 2rem;
     margin-top: 2rem
  }

  .buttons-modal-confirm button {
    width: 160px;
    height: 48px;
    padding: 4px 0px;
    border-radius: 4px;
    color: #FFFFFF;
  }

  .btn-no {
    border: 1px solid #FFFFFF;
    background: transparent;
  }

  .btn-yes {
    background: #5A78FF;
    border-radius: 4px;
  }

  .label {
    width: 100%;
    height: 92px;
  }

  .infos-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .info-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    align-items: flex-end;
  }

  .info-text img {
    width: 24px;
    height: 24px;
  }

  .info-text p {
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    margin-left: 0.5rem
  }

  .reference-img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 198px;
  }

  .btn-next, .btn-attach {
    background: #5A78FF;
    color: #FFFFFF;
    padding: 4px 0px;
    border: none;
    border-radius: 4px;
    width: 220px;
    margin-top: 4rem;
  }

  .modal-file {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
  }

  .modal-file-imgs {
    background-color: #fff;
    padding: 12px 0;
    border-radius: 24px 24px 0px 0px;
    width: 100%;
    max-width: 500px;
    height: 140px;
    bottom: 0;
    position: absolute;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .selected-img {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .upload-file-img {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .upload-file-text {
    width: 80%;
    display: flex;
    flex-direction: row;
  }

  .upload-file-img {
    justify-content: space-between;
    z-index: 5;
    position: absolute;
  }

  .upload-file-img p {
    color: #8C9195;
    font-size: 14px;
    font-weight: 400;
    margin-left: 0.5rem
  }

  .upload-file-img img {
    width: 24px;
    height: 24px
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #E5E5E5;
    margin-top: 1rem;
    margin-bottom: 1rem;
    z-index: 6;
    position: relative;
  }

  input[type="file" i] {
    background-color: transparent;
    color: transparent;
  }

  input::file-selector-button {
    background: transparent;
    color: transparent;
    border: none;
    z-index: 6;
    position: relative;
  }

  .location-input {
    border: 2px solid #F2F3F8;
    border-radius: 4px;
    padding: 16px, 10px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row
  }

  .location-input p {
    font-size: 15px;
    font-weight: 400;
    color: #A1A4AB;
    margin-left: 0.5rem
  }

  .location-input img {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem
  }

  .loovi-map {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
  appearance: none;
  border: 2px solid #eff2f7;
  border-radius: 4px;
  height: 50px;
  padding: 8px;
  color: #899299;
  font-size: 15px;
  width: 100%;
}

.loovi-map:hover {
  border-color: #b3b3b3;
}
`;


const ModalVerify = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  background-color: rgba(81, 81, 81, 0.25);

  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }

  @media (orientation: portrait) {
    img {
      width: 100vw;
      height: 100vh;
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  @media (orientation: landscape) {
    img {
      width: ${(props)=> props.isPhotoHorizontal ? "100%" : "100vw"};
    }
  }


  & > div{
    position: absolute;
    bottom: 0;

    height: 117px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.72);

    color: white;
    font-size: 16px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    div{
      display: flex;
      justify-content: center;
      gap: 22px;
      width: 100%;
    }

    button:first-child{
      background-color: transparent;
      border: 1px solid white;
    }

    button:last-child{
      border: none; 
    }

    button{
      width: 120px;
      height: 43px;
      color: white;
      background-color: rgba(90, 120, 255, 1);
      border-radius: 4px;
    }
  }
    
`

const ImagePhoto = styled.div`
`

const ButtonCapture = styled.div`
  width: 50px;
  height: 50px;
  background-color: rgba(90, 120, 255, 1);
  border-radius: 50%;

  position: absolute;
  z-index: 100;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (orientation: landscape) {
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    margin-left: auto;
  }

`