import React, { useEffect, useState } from "react"
import styled from "styled-components"
import looviIconColorImage from '../../../assets/images/loovi-logo-icon-color.png'
import { api_keyed, URLS } from "../../../helpers/Request"

export const Comments = ({chamadoId})=>{
    const [DataComments, setDataComments] = useState([])
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === DataComments.length - 1 ? 0 : prev + 1));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const months = ['jan.', 'fev.', 'mar.', 'abr.', 'mai.', 'jun.', 'jul.', 'ago.', 'set.', 'out.', 'nov.', 'dez.'];
        let day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear().toString().slice(-2);
        let hours = date.getUTCHours() - 3;

        if (hours < 0) {
            hours += 24;
            day -= 1;
        }

        const minutes = date.getUTCMinutes();
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${day} de ${month} ${year} às ${hours}:${formattedMinutes}`;
    }

    const compareDate = (a,b) => {
        const data1 = new Date(a.CreatedDate)
        const data2 = new Date(b.CreatedDate)
        return data1 - data2 
    }

    useEffect(()=>{
        (async()=>{
            try {
                if(chamadoId){
                    const result =  await api_keyed.get(
                        `${URLS.SALES_FORCE}/consultas/comentarios_do_caso_por_id/${chamadoId}`
                    ).json();

                    if(result.length > 2){
                        setDataComments(result.sort(compareDate).slice(-3).reverse())
                    }else{
                        setDataComments(result)
                    }
                }
            } catch (err) {
                console.log("Nenhum comentário encontrado!", err)
            }
        })()
    },[chamadoId])
    

    if(!DataComments.length){
        return <></>
    }
    
    return <Container>
        <h4>Atualização</h4>
        <BoxCards onClick={nextSlide} currenComment={currentSlide +1} nexComment={currentSlide === 2 ? 1 : currentSlide +2}>
            {
                DataComments.map((element,index) =>{
                    return <Card isVisible={index === currentSlide}>
                        <HeaderCard>
                            <img src={looviIconColorImage} alt="Logo-Loovi" />
                            <p></p>
                        </HeaderCard>
                        <MainCard>
                            <p>{element.CommentBody}</p>
                            <span>{formatDate(element.CreatedDate)}</span>
                        </MainCard>
                    </Card>
                })
            } 
        </BoxCards>
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;

    h4{
        font-weight: 700 !important;
        font-size: 16px !important;
        color: rgba(81, 81, 81, 1) !important;
        text-align: start !important; 
        margin: 0;
    }
`
const BoxCards = styled.ul`
    display: flex;
    justify-content: center;

    min-height: 190px;
    padding: 0;
    margin: 0;

    position: relative;

    li:nth-child(${(props) => props.currenComment}){
        top: -10px;
        z-index: 2;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
    }

    li:nth-child(${(props) => props.nexComment}){
        width: 94%;
        box-shadow: 0px 12px 8px 0px rgba(0, 0, 0, 0.05);
        opacity: 1;
    }

`

const Card = styled.li`
    width: 100%;
    height:152.1px;
    padding: 16px;
    border-radius: 12px;
    border: solid 1px rgba(224, 224, 238, 1);
    background-color: rgba(238, 242, 250, 1);
    color: rgba(81, 81, 81, 1);

    display: flex;
    flex-direction: column;
    gap: 12px;

    
    position: absolute;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transform: ${(props) => (props.isVisible ? 'translateY(0)' : 'translateY(10%)')};

`

const HeaderCard = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;


    img{
        width: 34.13px;
        height: 14px;
    }

    p{
        font-weight: 700;
        font-size: 14px;
        color: rgba(81, 81, 81, 1);
    }
`

const MainCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    p{
        font-size: 14px !important;
        line-height: 16.94px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        white-space: normal;

        height: 62px;

        font-size: 14px;
        font-weight: 400;
        text-align: justify;
    }

    span{
        display: block;

        color: rgba(161, 164, 171, 1);
        font-size: 12px;
        text-align: end;
    }
`