import React from "react";
import styled from "styled-components";

export const Welcome = (props) => {
  if (props.inline) {
    const shortName = !!props.customerName
      ? props.customerName.split(" ")[0]
      : "";
    return (
      <Section inline={props.inline}>
        <h2 className="loovi_header_greetings">
          Olá {shortName}, <span>Seja bem-vindo</span>
        </h2>
      </Section>
    );
  } else {
    return (
      <Section inline={props.inline}>
        <h2>Olá {props.customerName},</h2>
        <h4>Seja bem-vindo</h4>
      </Section>
    );
  }
};

const Section = styled.section`
  position: relative;

  h2,
  h4 {
    margin: 0;
  }

  h4,
  h2 span {
    color: #ffffff;
    font-family: "BrownStd-Regular";
  }
`;

export default Welcome;
