import React from 'react'

export const Input = (props) => {

    const name = props.name

    return(
        <label className={props.className}>
            <input 
                name={name} 
                ref={props.required ? props.register({ required: true }) : ''}
                placeholder={props.placeholder} 
                onChange={props.handleChange} 
                className={ props.errors ? 'loovi-field loovi-field-error' : 'loovi-field' } 
                type="text"/>
            {props.errors && <span className="loovi-msg-error">{props.errorMessage}</span>}
        </label>
    )
}

export default Input