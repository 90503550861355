// Dependencias

import React from 'react'
import styled from 'styled-components'

// Componentes

import Header from '../Header'
import Body from '../Body'
import { Container, Row, Col } from 'react-bootstrap'
import LoaderAnimation from '../LoaderAnimation'

// Imagens
import looviIconImage from '../../assets/images/loovi-logo-icon.png'
import { api_keyed, URLS } from '../../helpers/Request'
import Version from '../Version'

export class CodigoValidacao extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            customerCodValidacao: 'codigo-por-sms',
            isLoading: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return { ...prevState, data: nextProps.data }
    }

    proceed = event => {
        event.preventDefault()

        const { data, customerCodValidacao } = this.state

        const { cpf } = data

        this.setState({ isLoading: true })
        api_keyed.post(`${URLS.AUTENTICACAO}/cliente/${cpf}/${customerCodValidacao}`).json().then(data => {
            this.setState({ isLoading: false })
            this.props.nextStep({ ...this.state.data, ...data, cpf })
        });
    }

    componentDidMount() {
        document.body.style.backgroundColor = '#5A78FF'
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value })
    }

    render() {

        const { customerCodValidacao, isLoading } = this.state
        const { data = {} } = this.state

        //console.log(this.props)

        return (
            <Section>
                <LoaderAnimation show={isLoading} />
                <Header background="#5A78FF">
                    <figure className="loovi-icon"><img src={looviIconImage} alt="Loovi" /></figure>
                    <span className="loovi-spacer"></span>
                    <span className="loovi-spacer"></span>
                </Header>
                <Container >
                    <Body>
                        <Row>
                            <Col sm={12} md={{ span: 6, offset: 3 }}>
                                <h1 className="loovi-align">Código de Validação</h1>
                                <p className="loovi-align">Agora selecione a plataforma você quer receber o código de validação.</p>
                                <div className="loovi-spacer" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={{ span: 4, offset: 4 }}>
                                <form>
                                    {data.Sms && (
                                        <label className="loovi-radio">
                                            Telefone {data.Sms}
                                            <input type="radio" name="codValidacao" checked={customerCodValidacao === 'codigo-por-sms' ? true : false}
                                                value='codigo-por-sms'
                                                onChange={this.handleChange('customerCodValidacao')} />
                                            <span className="checkmark"></span>
                                        </label>
                                    )}

                                    {data.Email && (
                                        <label className="loovi-radio">
                                            Email {data.Email}
                                            <input type="radio" name="codValidacao" checked={customerCodValidacao === 'codigo-por-email' ? true : false}
                                                value='codigo-por-email'
                                                onChange={this.handleChange('customerCodValidacao')} />
                                            <span className="checkmark"></span>
                                        </label>
                                    )}
                                    <input onClick={this.proceed} className="loovi-button" type="submit" value="Enviar" />
                                </form>
                                <span className="loovi-spacer" />
                                <p className="loovi-info">Obs.: O código pode demorar alguns minutos para chegar.</p>
                                <Version center />
                            </Col>
                        </Row>
                    </Body>
                </Container>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;
    color: #FFFFFF;
    font-size: 16px;

    .loovi-icon{

        padding-top: 30px;
    }

    .loovi-info{

        @media(min-width: 900px){

            text-align:center;
        }
    }

    label{

        margin: 5px 0 5px 0;
    }

    .loovi-button{

        background: #FFFFFF;
        color: #393043;
    }

    h1{
        font-size: 36px !important;
        color: #FFFFFF;
        letter-spacing: -1.29px;
        line-height: 43px;
    }
`

export default CodigoValidacao