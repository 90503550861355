import React, { useEffect, useState } from 'react';
import { api_auth, URLS } from '../../../helpers/Request';
import styled from 'styled-components';
import NavBar from '../NavBar';
import iconClose from "../../../assets/images/arrow-left-blue.png"
import {isMobile} from 'react-device-detect';

export const ModalDocumentation = ({closeModalDocumentation, nCtrId, chamado})=>{
    const [stepsDocumentation, setStepsDocumentation] = useState([
        {
          title: "CNH Aberta",
          status: "Em análise",
          id:"cnh"
        },
        {
          title: "CRLV do automóvel",
          status: "Em análise",
          id:"clrv"
        },
        {
          title: "Boletim de  ocorrência",
          status: "Em análise",
          id:"boletim"
        },
        {
          title: "Dianteira do veículo com lateral do motorista",
          status: "Em análise",
          id:"veiculo-dianteira-esquerda"
        },
        {
          title: "Traseira do veículo com lateral do passageiro",
          status: "Em análise",
          id:"veiculo-traseira-direita"
        },
        {
          title: "Primeira foto do dano ao veículo",
          status: "Em análise",
          id:"dano-primeira-foto"
        },
        {
          title: "Segunda foto do dano ao veículo",
          status: "Em análise",
          id:"dano-segunda-foto"
        },
        {
          title: "Orçamento",
          status: "Em análise",
          id:"orcamento"
        },
    ]);

    useEffect(()=>{
        (async()=>{
            try {
                const resultFotos = await api_auth
                    .get(
                    `${URLS.FOTOS}/${nCtrId}/${chamado}`
                    ).json();

                setStepsDocumentation((prev)=>{
                    return prev.map(element => {
                        const findStep = resultFotos.find(photo=> element.id === photo.TipoFoto.Id )
    
                        if(findStep.Foto && "Aprovacao" in findStep.Foto){
                            if(findStep.Foto.Aprovacao){
                              return {...element, status: "Aprovada"}
                            }else{
                              return {...element, status: "Reprovado"}
                            }
                        }else{
                            return {...element, status: "Em análise"}
                        }
                    });
                })

            } catch (err) {
                console.log("Error ao buscar fotos!", err)
            }
        })()
    },[chamado, nCtrId])

    const handleResetPhoto = async(id, status)=>{
      if(status === "Reprovado"){
        window.location.href = "/sinistros"
        closeModalDocumentation()
      }
    }

    return <Container>
      <NavBar backButton blueArrow  closeModal={closeModalDocumentation} />
      <h3
        className="loovi-header-title title-color"
        style={{ textAlign: "center", marginBottom: "3.5rem" }}
      >
        {!isMobile && <img src={iconClose} alt="" onClick={closeModalDocumentation} />}
        DOCUMENTAÇÃO
      </h3>
      <div className='box-data'>
        <div className="data-content">
          {stepsDocumentation.map((step, i) => (
            <>
              <div className="documentation-content" key={step.id} onClick={()=> handleResetPhoto(step.id, step.status)}>
                <p className="documentation-content-text">{step.title}</p>
                <div
                  className="status-documentation"
                  style={{
                    color:
                      step.status === "Aprovada"
                        ? "#02AF5C"
                        : step.status === "Reprovado"
                          ? "#ED3D3D"
                          : "#999999",
                    background:
                      step.status === "Aprovada"
                        ? "#DAFFED"
                        : step.status === "Reprovado"
                          ? "#FFEDED"
                          : "#EEF2FA",
                  }}
                >
                  {step.status}
                </div>
              </div>
              {i !== stepsDocumentation.length - 1 && (
                <div
                  style={{
                    height: "1px",
                    background: "#EEF2FA",
                    width: "100%",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                ></div>
              )}
            </>
          ))}
        </div>
      </div>
  </Container>
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;  

  background-color: white;
  overflow-y: auto;
  padding-bottom: 3.5rem;

  .box-data{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .data-content{
    padding-inline: 20px;
    max-width: 620px;
  }

  h3{
    position: relative;

    img{
      position: absolute;
      top: 12px;
      left: 25%;

      height: 20px;

      cursor: pointer;
    }
  }
`