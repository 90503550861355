// Dependencias

import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

// Componentes

import { MobileView, BrowserView } from "react-device-detect"
import AddCreditCardMobile from './AddCreditCardMobile'
import AddCreditCardBrowser from './AddCreditCardBrowser'
import Feedback from './Feedback'

import { authHandler } from '../../helpers/auth.js'

export class AddCreditCard extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            step: 1,
            customerCars: [],
            customerSelectedCar: {},
            isLoading: false,
            customerCardNumber: '',
            customerCardName: '',
            customerCardExpiration: '',
            customerCardCV: 0,
            customerCarsCard: '',
            customerDefaultCard: ''

        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        if (authHandler.isLogged()) {
            this.setState(Lockr.get('ClientData'))
        }
        this.setState({ isLoading: false })
    }

    proceed = () =>{

        //console.log('Salvando dados do cartao...');
        this.setState({ step: 2 });
    }

    handleChange = event => {

        const target = event.target
        const name = target.name
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value})
    }

    handleSelectChange = selectedOption => {
        this.setState({ isLoading: true })
        //console.log(selectedOption);
        this.setState(
            { customerSelectedCar: selectedOption }
        );

        let clientData;
        
        if (authHandler.isLogged()) {
            clientData = Lockr.get('ClientData');
            //console.log(clientData);

            Lockr.set('ClientData', {
                IdCustomerSelectedCar: clientData.customerSelectedCar.value,
                customerCars: clientData.customerCars,
                customerSelectedCar: selectedOption,
                customerInfo: clientData.customerInfo
            });
                
        }
        this.setState({ isLoading: false })
    };

    render(){

        const {
            step,
            customerCars,
            customerSelectedCar,
            customerCardNumber,
            customerCardName,
            customerCardExpiration,
            customerCardCV,
            customerCarsCard,
            customerDefaultCard} = this.state

        const values = {
            customerCars,
            customerSelectedCar,
            customerCardNumber,
            customerCardName,
            customerCardExpiration,
            customerCardCV,
            customerCarsCard,
            customerDefaultCard
        }

        switch (step) {
            case 2 :

                return(
                    <Feedback
                        feedbackTitle="Meio de Pagamento"
                        alertTitle="Seu cartão de crédito foi atualizado com sucesso!"
                        contentTitle="Protocolo 1234567890">
                        <p>Novo cartão de crédito adicionado.</p>
                        <p>As próximas mensalidades serão debitadas neste novo cartão.</p>
                    </Feedback>
                )

            default:

                return(
                    <Section>
                        <MobileView>
                            <AddCreditCardMobile
                                values={values}
                                handleSelectChange={this.handleSelectChange}
                                //handleChange={this.handleChange}
                                proceed={this.proceed} />
                        </MobileView>
                        <BrowserView>
                            <AddCreditCardBrowser
                                values={values}
                                handleSelectChange={this.handleSelectChange}
                                //handleChange={this.handleChange}
                                proceed={this.proceed} />
                        </BrowserView>
                    </Section>
                )
        }
    }
}

const Section = styled.section`
    position: relative;
`
