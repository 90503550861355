import React, { useState, useRef } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import './style.css';
import TravelsService from '../../../../services/TravelsService';
import { LoaderInline } from '../../../LoaderInline/index';
import { emitter } from '../../../../helpers/Events';

export default function TravelCard({ travelData, onDelete }) {
  const [isLoading, setIsLoading] = useState(false);
  const cardRef = useRef();

  async function deleteTravel(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    const result = await TravelsService.deleteTravel(travelData.Id);

    if (!result) {
      alert("Não foi possível deletar viagem. Tente novamente.");
      setIsLoading(false);
      return;
    }

    if (onDelete) {
      onDelete(travelData.Id);
    }

    setIsLoading(false);
  }

  function handleSelection() {
    if (travelData.selected) {
      emitter.emit('travelRemove', travelData);
    } else {
      emitter.emit('travelAdd', travelData);
    }
  }

  return (
    <div className={travelData.selected ? 'travel_card travel_card--selected' : 'travel_card'} ref={cardRef} onClick={handleSelection}>
      <div className="travel_card__header">
        <time>{format(new Date(travelData.DataHoraInicio * 1000), "dd LLL yyyy", { locale: ptBR })}</time>
        <button onClick={deleteTravel} disabled={isLoading}>
          {isLoading && <LoaderInline />}
          {!isLoading && <img src="/img/icon-map-trash.svg" alt=""/>}
        </button>
      </div>
      <hr/>
      <div className="travel_card__stats">
        <div className="travel_card__stats__col">
          <img src="/img/icon-map-distance.svg" alt=""/>
          <h2>{travelData.totalDistance}</h2>
          <p>Dist. Total</p>
        </div>
        <div className="travel_card__stats__col">
          <img src="/img/icon-map-medvel.svg" alt=""/>
          <h2>{travelData.mediumVelocity}</h2>
          <p>Vel. Média</p>
        </div>
        <div className="travel_card__stats__col">
          <img src="/img/icon-map-maxvel.svg" alt=""/>
          <h2>{travelData.maximumVelocity}</h2>
          <p>Vel Máxima</p>
        </div>
      </div>
      <hr/>
      <div className="travel_card__details">
        <div className="travel_card__ab">
          <div className="travel_card__ab__a">
            <span>A</span>
            <div></div>
          </div>

          <div className="travel_card__ab__line"></div>

          <div className="travel_card__ab__b">
            <span>B</span>
            <div></div>
          </div>
        </div>
        <div className="travel_card__address">
          <div><time>{format(new Date(travelData.DataHoraInicio * 1000), "dd/MM hh:mm", { locale: ptBR })}</time> {travelData.EnderecoInicio}</div>
          <div><time>{format(new Date(travelData.DataHoraFim * 1000), "dd/MM hh:mm", { locale: ptBR })}</time> {travelData.EnderecoFim}</div>
        </div>
      </div>
    </div>
  );
};
