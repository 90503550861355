import React, { useEffect, useState } from "react";
import Lockr from "lockr";
import TravelsService from "../../../services/TravelsService";
import Header from "../../Header";
import NavBar from "../NavBar";

import "./style.css";
import TravelCard from "./TravelCard/index";
import { LoaderInline } from "../../LoaderInline";
import TravelMap from "./TravelMap";
import { emitter } from "../../../helpers/Events";
import Sidebar from "../Sidebar";

let nextPageToken = false;
let firstPage = true;
let isFetching = false;
let staticTravels = [];

export default function Travels(props) {
  const clientData = Lockr.get("ClientData");
  const currentContract = clientData?.customerSelectedCar?.contractInfo?.nCtrId;
  const hasDevice =
    clientData?.customerSelectedCar?.contractInfo?.possuiDispositivo;
  const [travels, setTravels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasTravelSelected, setHasTravelSelected] = useState(false);

  async function loadTravels() {
    isFetching = true;
    setIsLoading(true);
    const result = await TravelsService.getLastTravelsByContract(
      currentContract,
      10,
      nextPageToken
    );

    if (!firstPage && !result.PossuiProximaPagina) {
      // chegou ao fim das paginas
      return;
    }

    if (!result) {
      alert(
        "Não foi possível carregar suas viagens. Atualize a página e tente novamente."
      );
      isFetching = false;
      setIsLoading(false);
      return;
    }

    if (result.PossuiProximaPagina) {
      nextPageToken = result.ProximaPagina;
    } else {
      nextPageToken = false;
    }

    setTravels([...travels, ...result.Itens]);
    staticTravels = [...travels, ...result.Itens];
    isFetching = false;
    setIsLoading(false);
    firstPage = false;
  }

  function handleTravelScroll(e) {
    const currentScroll = e.target.scrollTop;
    const height = e.target.scrollHeight - e.target.offsetHeight;

    if (height - currentScroll <= 400 && !isFetching) {
      loadTravels();
    }
  }

  function handleDelete(travelId) {
    const index = travels.findIndex((travel) => travel.Id === travelId);

    if (index === -1) {
      return;
    }

    travels.splice(index, 1);

    setTravels([...travels]);
    staticTravels = [...travels];
  }

  function handleTravelAdd(travelData) {
    const index = staticTravels.findIndex(
      (travel) => travel.Id === travelData.Id
    );

    if (index === -1) {
      return;
    }

    staticTravels[index].selected = true;
    setTravels([...staticTravels]);
    setHasTravelSelected(true);
  }

  function handleTravelRemove(travelData) {
    const index = staticTravels.findIndex(
      (travel) => travel.Id === travelData.Id
    );

    if (index === -1) {
      return;
    }

    staticTravels[index].selected = false;
    setTravels([...staticTravels]);

    if (staticTravels.findIndex((travel) => travel.selected) === -1) {
      setHasTravelSelected(false);
    }
  }

  function handleTravelRemoveAll() {
    staticTravels.forEach((travel) => (travel.selected = false));
    setTravels([...staticTravels]);
    setHasTravelSelected(false);
  }

  function deselectAll() {
    emitter.emit("travelRemoveAll");
  }

  useEffect(() => {
    loadTravels();

    emitter.on("travelAdd", handleTravelAdd);
    emitter.on("travelRemove", handleTravelRemove);
    emitter.on("travelRemoveAll", handleTravelRemoveAll);

    return () => {
      emitter.off("travelAdd", handleTravelAdd);
      emitter.off("travelRemove", handleTravelRemove);
      emitter.off("travelRemoveAll", handleTravelRemoveAll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="travels">
      <Header background="#5A78FF">
        <NavBar
          background="#5A78FF"
          titleColor="#FFFFFF"
          burgerWhite
          backButton
        >
          Minhas Viagens
        </NavBar>
      </Header>

      <div className="travels__body">
        <div className="loovi-fixed-side-column">
          <Sidebar />
        </div>
        <div className="travels__map">
          <TravelMap />
        </div>
        {hasDevice && (
          <div className="travels__list" onScroll={handleTravelScroll}>
            {travels.length === 1 && <h3>1 Viagem</h3>}
            {travels.length !== 1 && (
              <h3>
                {travels.length} Viagens{" "}
                {hasTravelSelected && (
                  <button onClick={deselectAll}>
                    <img src="/img/icon-map-trash-white.svg" alt="" /> Limpar
                  </button>
                )}
              </h3>
            )}
            <div className="travels__list__scroll">
              {travels.map((travel) => (
                <TravelCard
                  key={travel.Id}
                  travelData={travel}
                  onDelete={handleDelete}
                />
              ))}
              {isLoading && (
                <div className="travels__list__loader">
                  <LoaderInline size={40} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
