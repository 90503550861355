// Dependencias

import React from 'react'
import styled from 'styled-components'

// Components
import Header from '../Header'
import NavBar from '../painel/NavBar'
import { Container, Row, Col } from 'react-bootstrap'

export const AdditionalServicesMobile = (props) => {

    return (
        <Section>
            <Header background="#5A78FF" > 
                <NavBar background="#5A78FF" titleColor="#FFFFFF" backButton burgerWhite>Serviços Adicionais</NavBar>
{/*                 <Container className="loovi-align-left">
                    <Row>
                        <Col sm={12} md={{ span: 6, offset: 3 }}>
                            <p className="loovi-center loovi-bold">Selecione o veículo</p>
                            <LooviSelect classNamePrefix="react-select"
                                value={props.values.customerSelectedCar}
                                onChange={props.handleSelectChange}
                                options={props.values.customerCars} />
                            <span className="loovi-spacer"></span>
                            <h3 className="loovi-header-title loovi-color-0">Serviços adicionais</h3>
                        </Col>
                    </Row>
                </Container> */}
            </Header>
            <div className="loovi-content">
                <Container className="loovi-padding">
                    <Row>
                        <Col sm={12} md={{ span: 6, offset: 3 }}>
                            <div className="loovi-box loovi-shadow">
                                <h4 className="loovi-color-2">Serviços Adicionais</h4>
                                <div className="loovi-box-body">
                                    <p>Para adquirir serviços adicionais como reboque e assistência 24h, entre em contato.</p>
                                    <h5 className="loovi-color-2">4000 1762</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col sm={12} md={{ span: 6, offset: 3 }}>
                            <p className="loovi-color-5 loov-terms">
                                Todos os serviços adicionais tem o período de carência de 30 dias corridos, contando do momento da aquisição do serviço. A cobrança será realizada imediatamente à contratação no mesmo cartão de crédito associado a sua conta.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Section>
    )
}

const Section = styled.section`

    hr{

    margin: 0;
    }

    .loovi-align-left{

    text-align: left !important;
    }

    .loovi-content{

    position: relative;

    &::before{

        content: '';
        display: block;
        width: 100%;
        height: 130px;
        background: #5A78FF;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    }

    .loov-terms{

    padding-top: 40px;
    font-size: 15px;
    }

    .loovi-padding{

    padding-left: 20px;
    padding-right: 20px;
    }

    .loovi-box{

    background: #FFFFFF;
    border-radius: 8px;
    font-size: 14px;
    color: #899299;
    position: relative;

    h4{

        text-align: center;
        padding: 10px 0;
        margin-bottom: 0;
        border-bottom: solid 1px #D8D8D8;
        font-size: 22px;
    }

    button{

        color: #FFFFFF;
        background: #5A78FF;
        border-radius: 5px;
        padding: 8px 15px;
        border: none;
        height: auto;
        font-size: 16px;
        letter-spacing: -0.57px;

        &:hover{

            background: #57D9A3;
        }
    }
    }

    .loovi-info__price{

    h4{
        padding: 0;
    }

    small{

        font-size: 12px;
    }
    }

    .loovi-box-body{

    padding: 20px;
    }

    .loovi-radio{

    padding-top: 2px;
    }

    .checkmark{

    background: #EFF2F7 !important;
    }
`

export default AdditionalServicesMobile