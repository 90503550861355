import React, { useEffect, useState } from "react"
import {  api_keyed, URLS } from "../../../helpers/Request"
import LoaderAnimation from "../../LoaderAnimation";
import styled from 'styled-components';
import NavBar from "../NavBar";
import iconClose from "../../../assets/images/arrow-left-blue.png"
import {isMobile} from 'react-device-detect';

export const ModalDataCase = ({closeModalData, chamado})=>{
  const [isLoading, setLoading] = useState(false);
  const [caseData, setCaseData] = useState({
    reason: "",
    caseNumber:"",
    subReason: "",
    description: "",
    location: "",
    referencePoint: "",
    timeOfOccurence: "",
    driver: "",
    driverCPF: "",
    driverContact: "",
    isVehicleMoving: "",
    incidentReportCarriedOut: "",
    involvementWithThirdParties: "",
    dateCreated: "",
    currentVehicleLocation:"",
    isOpenProtocolToThirdParties:"",
    thirdPartyContact:"",
  })

  const handleFormatDate = (date, isHour=false)=>{
    const data = new Date(date);
    const day = String(data.getUTCDate()).padStart(2, '0');
    const mes = String(data.getUTCMonth() + 1).padStart(2, '0');
    const ano = data.getUTCFullYear();
    const hours = data.getHours()
    const minutes = data.getMinutes()

    return isHour ? `${day}/${mes}/${ano} ás ${hours}:${minutes}` : `${day}/${mes}/${ano}`
  }

  const handlewDowloadpdf = async()=>{
    try {
      setLoading(true)
      const reason = caseData.reason === "Colisão" ? "colisao" : "vidros"
      const result =  await api_keyed.get(
        `${URLS.DOCUMENTOS_AUXILIARES}/url-download/${chamado}/${reason}/termo-de-acionamento`
      ).json();

      const link = document.createElement('a');
      link.href = result;
      link.target = "target _blank"
      
      link.click();
      closeModalData()
    } catch (err) {
      console.log("Error ao baixar pdf!", err)
    }
    setLoading(false)
    
  }

  useEffect(()=>{
    (async()=>{
      setLoading(true)
      try {
        const result =  await api_keyed.get(
          `${URLS.SALES_FORCE}/consultas/informacoes_caso_sinistro_por_numero/${chamado}`
        ).json();

        setCaseData(()=>{
          return {
            reason: result[0]["Reason"],
            caseNumber:result[0]["CaseNumber"],
            subReason: result[0]["Sub-Reason"],
            description: result[0]["Descricao_do_ocorrido__c"],
            location: result[0]["QualLocalSinistro__c"],
            referencePoint: result[0]["Ponto_de_referencia__c"],
            timeOfOccurence:  handleFormatDate(result[0]["Data_Hora_da_ocorrencia__c"], true),
            driver: result[0]["Condutor__c"],
            driverCPF: result[0]["CPF_do_condutor__c"],
            driverContact: result[0]["Contato_do_condutor__c"],
            isVehicleMoving: result[0]["Veiculo_esta_se_locomovendo__c"],
            incidentReportCarriedOut: result[0]["Boletim_realizado__c"],
            involvementWithThirdParties: result[0]["Envolvimento_com_terceiros__c"],
            dateCreated: handleFormatDate(result[0]["CreatedDate"]),
            currentVehicleLocation:result[0]["Reason"],
            isOpenProtocolToThirdParties:result[0]["Contato_do_terceiro__c"] ? "Sim":"Não" ,
            thirdPartyContact:result[0]["Contato_do_terceiro__c"],
          }
        })
              
      } catch (err) {
        console.log("Error ao buscar dados do caso!", err)
      }

      setLoading(false)
    })()
  },[chamado])

  if(isLoading){
    return <LoaderAnimation show={isLoading} />
  }
  

  return <Container>
    <NavBar backButton blueArrow  closeModal={closeModalData} />
    <h3
      className="loovi-header-title title-color"
      style={{ textAlign: "center", marginBottom: "3.5rem" }}
    >
      {!isMobile && <img src={iconClose} alt="" onClick={closeModalData} />}
      Abertura
    </h3>

    <div className='box-data'>

      <div className="data-content">
        <div className="data-content" onClick={closeModalData}>
          <div className="data-content-text">
            <p className="data-title">MOTIVO DO CASO:</p>
            <p className="data-text">{caseData.reason}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">NUMERO DO CASO:</p>
            <p className="data-text">{caseData.caseNumber}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">DESCRIÇÃO DO OCORRIDO:</p>
            <p className="data-text">
            {caseData.description}
            </p>
          </div>
          <div className="data-content-text">
            <p className="data-title">LOCAL DO SINISTRO:</p>
            <p className="data-text">
            {caseData.location}
            </p>
          </div>
          <div className="data-content-text">
            <p className="data-title">PONTO DE REFERÊNCIA:</p>
            <p className="data-text">{caseData.referencePoint}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">DATA/HORA DA OCORRÊNCIA:</p>
            <p className="data-text">{caseData.timeOfOccurence}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">CONDUTOR:</p>
            <p className="data-text">{caseData.driver}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">CPF DO CONDUTOR:</p>
            <p className="data-text">{caseData.driverCPF}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">CONTATO DO CONDUTOR:</p>
            <p className="data-text">{caseData.driverContact}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">VEÍCULO LOCOMOVENDO:</p>
            <p className="data-text">{caseData.isVehicleMoving}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">BOLETIM REALIZADO:</p>
            <p className="data-text">{caseData.incidentReportCarriedOut}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">ENVOLVIMENTO COM TERCEIROS:</p>
            <p className="data-text">{caseData.involvementWithThirdParties}</p>
          </div>
          <div className="data-content-text">
            <p className="data-title">ABRIR PROTOCOLO PARA TERCEIRO:</p>
            <p className="data-text">{caseData.isOpenProtocolToThirdParties}</p>
          </div>
          {caseData.thirdPartyContact && <div className="data-content-text">
            <p className="data-title">CONTATO DO TERCEIRO:</p>
            <p className="data-text">{caseData.thirdPartyContact}</p>
          </div>}
          <div className="data-content-text">
            <p className="data-title">TERMOS E CONDIÇÕES:</p>
            <p className="data-text">Aceito em {caseData.dateCreated}</p>
          </div>
        </div>
        <button className="btn-download" onClick={handlewDowloadpdf}>
          <p className="material-icons" style={{ marginRight: "0.3rem" }}>
            download
          </p>
          <p>Baixar aviso de sinistro</p>
        </button>
      </div>
    </div>
  </Container>
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;  

  background-color: white;
  overflow-y: auto;
  padding-bottom: 3.5rem;

  .box-data{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .data-content{
    padding-inline: 20px;
    max-width: 620px;
  }

  h3{
    position: relative;

    img{
      position: absolute;
      top: 12px;
      left: 25%;

      height: 20px;
      cursor: pointer;
    }
  }


`