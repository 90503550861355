// Dependencias

import React from 'react'
import styled from 'styled-components'

// Components
import Header from '../Header'
import NavBar from '../painel/NavBar'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../painel/Sidebar'

export const AdditionalServicesBrowser = (props) => {

    return (
        <Section>
            <Header background="#5A78FF">
                <NavBar background="#5A78FF" titleColor="#FFFFFF" burgerWhite backButton>Trocar Cartão</NavBar>
            </Header>
            <Container fluid>
                <Row>
                    <div className="loovi-fixed-side-column">
                        <Sidebar />
                    </div>
                    <div className="loovi-fluid-column">
                        <div className="loovi-descktop-padding">
                            <Row>
                                <Col><h3 className="loovi-title">Serviços Adicionais</h3></Col>
                            </Row>
                            <Row className="loovi-full">
                                <Col sm={12} md={6}>
                                    <div className="loovi-box loovi-shadow">
                                        <h4 className="loovi-color-2">Serviços Adicionais</h4>
                                        <div className="loovi-box-body">
                                            <p>Para adquirir serviços adicionais como reboque e assistência 24h, entre em contato.</p>
                                            <h5 className="loovi-color-2">4000 1762</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={5}>
                                    <p className="loovi-color-5 loov-terms">
                                    Todos os serviços adicionais tem o período de carência de 30 dias corridos, contando do momento da aquisição do serviço. A cobrança será realizada na próxima data de vencimento.</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </Section>
    )
}

const Section = styled.section`

    hr{

    margin: 0;
    }

    .loovi-align-left{

    text-align: left !important;
    }

    .loovi-content{

    position: relative;

    &::before{

        content: '';
        display: block;
        width: 100%;
        height: 130px;
        background: #5A78FF;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    }

    .loov-terms{

        font-size: 15px;
    }

    .loovi-padding{

        padding-left: 20px;
        padding-right: 20px;
    }

    .loovi-box{

        background: #FFFFFF;
        border-radius: 8px;
        font-size: 14px;
        color: #899299;
        position: relative;

        h4{

            text-align: center;
            padding: 10px 0;
            margin-bottom: 0;
            border-bottom: solid 1px #D8D8D8;
            font-size: 22px;
        }

        button{

            color: #FFFFFF;
            background: #5A78FF;
            border-radius: 5px;
            padding: 8px 15px;
            border: none;
            height: auto;
            font-size: 16px;
            letter-spacing: -0.57px;

            &:hover{

                background: #57D9A3;
            }
        }
    }

    .loovi-info__price{

        h4{
            padding: 0;
        }

        small{

            font-size: 12px;
        }
    }

    .loovi-box-body{

        padding: 20px;
    }

    .loovi-radio{

        padding-top: 2px;
    }

    .checkmark{

        background: #EFF2F7 !important;
    }
`

export default AdditionalServicesBrowser