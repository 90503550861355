import React, { useEffect, useState } from 'react';
import './SSO.css';
import { api, URLS } from '../../helpers/Request';
import { authHandler } from '../../helpers/auth';
import { getContratos } from '../../components/painel/services';

export default function SSO(props) {
  const [base64data, setBase64data] = useState(decryptData(props.match.params.data));

  function decryptData(data) {
    try {
      return JSON.parse(atob(data));
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  useEffect(() => {
    async function loadData() {
      let result;
      //let clientData

      try {
        result = await api.post(`${URLS.AUTENTICACAO}/cliente/obtem-autenticacao-sem-verificacao/${base64data.token}`).json();
      } catch (e) {
        result = false;
      }

      if (!result) {
        //console.log(result);
        await setBase64data(false);
        return;
      }

      if (!result.IdToken) {
        //console.log(result);
        await setBase64data(false);
        return;
      }

      authHandler.setToken(result.IdToken);
      authHandler.setAccessToken(result.AccessToken);
      authHandler.setRefreshToken(result.RefreshToken);
      await getContratos();

      //clientData = Lockr.get('ClientData');
      //console.log(clientData);    
      

      if (base64data.path) {
        if (base64data.path === '/dashboard'){
          window.location.href = `/dashboard/${base64data.contractId}`;  
        } else if (base64data.path === '/suporte'){
          if (base64data.upSell) {
            window.location.href = `/suporte/${base64data.contractId}/${base64data.upSell}`; 
          } else {
            window.location.href = `/suporte/${base64data.contractId}`; 
          }
        } else if (base64data.path === '/sinistros'){
          //alert(`Sinistros : ${base64data.contractId}`)
          window.location.href = `/sinistros/${base64data.contractId}`; 
        } else {
          window.location.href = base64data.path;
        }
      } else {
        window.location.href = `/dashboard/${base64data.contractId}`; 
      }
    }

    if (base64data) {
      loadData();
    }
  }, [base64data]);

  return (
    <div className="sso-main">
      {base64data && <h1>Autenticando...</h1>}
      {!base64data && <h1>Oops! Erro ao autenticar dados.</h1>}
    </div>
  )
}
