import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import Lockr from "lockr";
import randomColor from "randomcolor";

import "./style.css";
import { emitter } from "../../../../helpers/Events";
import TravelsService from "../../../../services/TravelsService";

const lineColors = randomColor({
  count: 100,
  hue: "blue",
});

const getRandomColor = () => lineColors.sort(() => 0.5 - Math.random())[0];

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

let staticTravelPaths = [];

export default function TravelMap({ travelData, onDelete }) {
  const clientData = Lockr.get("ClientData");
  const currentContract = clientData?.customerSelectedCar?.contractInfo?.nCtrId;
  const hasDevice =
    clientData?.customerSelectedCar?.contractInfo?.possuiDispositivo;
  const map = useRef();
  const [travelPaths, setTravelPaths] = useState([]);
  const [vehiclePosition, setVehiclePosition] = useState(false);
  const [userPosition, setUserPosition] = useState(false);
  const [isEngineOn, setIsEngineOn] = useState(false);
  const [mapPosition, setMapPosition] = useState(center);
  const [zoom, setZoom] = useState(20);

  const onLoad = React.useCallback(function callback(mapGoogle) {
    const bounds = new window.google.maps.LatLngBounds();
    mapGoogle.fitBounds(bounds);
    map.current = mapGoogle;
    setTimeout(() => {
      if (hasDevice) {
        loadVehiclePosition(true);
      } else {
        loadDevicePosition(true);
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = React.useCallback(function callback(mapGoogle) {
    map.current = null;
  }, []);

  function handleTravelAdd(travelData) {
    setZoom(14);
    const coords = travelData.CoordenadasValidas.map((coord) => {
      return {
        lat: +coord.lat,
        lng: +coord.long,
      };
    });

    setMapPosition({
      lat: coords[0].lat,
      lng: coords[0].lng,
    });

    staticTravelPaths.push({
      id: travelData.Id,
      coords,
      firstCoord: travelData.firstCoord,
      lastCoord: travelData.lastCoord,
      color: getRandomColor(),
    });

    setTravelPaths([...staticTravelPaths]);
  }

  function handleTravelRemove(travelData) {
    const index = staticTravelPaths.findIndex(
      (travelPath) => travelPath.id === travelData.Id
    );

    if (index === -1) {
      console.log("Não achou!");
      return;
    }

    staticTravelPaths.splice(index, 1);
    setTravelPaths([...staticTravelPaths]);
  }

  function handleTravelRemoveAll() {
    setTravelPaths([]);
    staticTravelPaths = [];
  }

  function centerMap(coords) {
    setMapPosition(coords);
  }

  async function loadVehiclePosition(shouldCenterMap = false) {
    console.log("Called! loadVehiclePosition");
    const isMapaScreen = window.location.pathname.includes("/mapa");

    if (!isMapaScreen) {
      return;
    }

    const result = await TravelsService.getCurrentPosition(currentContract);

    if (!result) {
      console.log("Não achou veículo. API retornou erro.");
      return;
    }

    setIsEngineOn(result.ign);
    setVehiclePosition({ lat: result.lat, lng: result.long });

    if (shouldCenterMap) {
      centerMap({ lat: result.lat, lng: result.long });
    }

    setZoom(14);

    setTimeout(() => {
      if (isMapaScreen) {
        loadVehiclePosition();
      }
    }, 5000);
  }

  async function loadDevicePosition(shouldCenterMap = false) {
    const isMapaScreen = window.location.pathname.includes("/mapa");

    if (!isMapaScreen) {
      return;
    }

    try {
      console.log("Pegando localização...");
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Localização pega!", position.coords);
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setUserPosition({ lat, lng });

        if (shouldCenterMap) {
          centerMap({ lat, lng });
        }

        setZoom(14);

        setTimeout(() => {
          if (isMapaScreen) {
            loadDevicePosition(true);
          }
        }, 5000);
      });
    } catch (e) {
      console.error(e);
      alert("Não foi possível identificar sua localização atual no mapa.");
    }
  }

  useEffect(() => {
    emitter.on("travelAdd", handleTravelAdd);
    emitter.on("travelRemove", handleTravelRemove);
    emitter.on("travelRemoveAll", handleTravelRemoveAll);
    emitter.on("centerMap", centerMap);

    return () => {
      emitter.off("travelAdd", handleTravelAdd);
      emitter.off("travelRemove", handleTravelRemove);
      emitter.off("travelRemoveAll", handleTravelRemoveAll);
      emitter.on("centerMap", centerMap);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="travel_map">
      <LoadScript googleMapsApiKey="AIzaSyDi6KSLEamd-XEGKF3vtk5B0J5bLwchLcs">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapPosition}
          zoom={zoom}
          streetView={false}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <>
            {vehiclePosition && (
              <Marker
                position={vehiclePosition}
                icon={`/img/icon-map-car-${isEngineOn ? "on" : "off"}.svg`}
              />
            )}
            {userPosition && (
              <Marker position={userPosition} icon={`/img/icon-map-user.svg`} />
            )}
            {travelPaths.map((travelPath) => (
              <>
                <Marker
                  key={travelPath.id + "-A"}
                  position={travelPath.firstCoord}
                  label="A"
                />
                <Marker
                  key={travelPath.id + "-B"}
                  position={travelPath.lastCoord}
                  label="B"
                />
                <Polyline
                  key={travelPath.id + "-LINE"}
                  path={travelPath.coords}
                  options={{ strokeColor: travelPath.color }}
                />
              </>
            ))}
          </>
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
