import React, { useEffect, useState } from "react";
import useForm from "react-hook-form";
import Lockr from "lockr";
import { Container, Row, Col } from "react-bootstrap";

import { MobileView, BrowserView } from "react-device-detect";

import Header from "../Header";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
//import LoaderAnimation from "../LoaderAnimation";

import * as masks from "../../helpers/masks";

import styled from "styled-components";
import SapService from '../../services/SapService';
import "../../styles/style.scss";

import checkIconImage from '../../assets/images/check-parabens.png'

export default function AdvancePayment(props) {
  const [isLoading, setLoading] = useState(false);
  const [isPossibleAdvancePayment, setIsPossibleAdvancePayment] = useState(true);
  const [txtIsPossibleAdvancePayment, setTxtIsPossibleAdvancePayment] = useState('');

  const [state, setState] = useState({
    nCtrId: "",
    advancePaymentMethod: { value: "MP", label: "-- Método de Pagamento --"}, 
    advancePaymentContent: { chaveAleatoria: "", dataExpiracao: "", link: "", pspReference: "", mensagem: "", sucesso: false}, 
    advancePaymentValue: "",
  });

  useEffect(() => {
    let clientData = Lockr.get("ClientData");
    //setLoading(true);

    async function fetchClientData() {
      try {
        setState((prevstate) => ({ ...prevstate, nCtrId: clientData.customerSelectedCar.contractInfo.nCtrId }));
        const response = await SapService.getAdvancePaymentValue(clientData.customerSelectedCar.contractInfo.nCtrId);
        
        if (response) {
          if (response.sucesso){
            setState((prevstate) => ({ ...prevstate, advancePaymentValue: masks.real(response.conteudo.valor)}));
          }else{
            setIsPossibleAdvancePayment(false);
            setTxtIsPossibleAdvancePayment(response.mensagem);
          }
        }
      } catch (error) {
        alert('Ops! ocorreu um erro, tente novamente mais tarde.');
      }
    }
    fetchClientData();
    //setLoading(false);
  },[]);

  const onSelectChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: { value, label: value } });
  };

  const onSubmit = async (data) => {
    console.log("onSubmit : ", state.nCtrId, " / ",  state.advancePaymentMethod.value);
    setLoading(true);
    const response = await SapService.getAdvancePaymentInfo(state.nCtrId, state.advancePaymentMethod.value);

    //PI 
    //const encodedString = `{"conteudo": {"dataExpiracao": "2022-11-04T18:23:15Z", "pspReference": "P57VV7DP8QHG5S82", "chaveAleatoria": "TestQRCodeEMVToken", "link": "https://test.adyen.com/hpp/generateQRCodeImage.shtml?url=TestQRCodeEMVToken"}, "sucesso": true, "mensagem": "QRCODE gerado com sucesso."}`;
    
    //LP
    //const encodedString = `{"conteudo": {"dataExpiracao": "2023-10-18T08:57:15Z","link": "https://test.adyen.link/PLE82FA9BFC0E362E2","pspReference": "PLE82FA9BFC0E362E2"},"sucesso": true,"mensagem": "Link gerado com sucesso."}`
    
    //CC
    //const encodedString = `{"conteudo": {},"sucesso": true,"mensagem": "Antecipação de pagamento solicitada com sucesso. Aguardar processamento de cobrança"}`
    
    //const response = JSON.parse(encodedString);
    
    console.log("response getAdvancePaymentInfo : ", response);
    if (response){
      if(response.sucesso){
        console.log(state.advancePaymentMethod.value);
        if(state.advancePaymentMethod.value === "PI"){
          setState({ ...state, advancePaymentContent: { 
            chaveAleatoria: response.conteudo.chaveAleatoria,
            dataExpiracao: response.conteudo.dataExpiracao,
            link: response.conteudo.link,
            pspReference: response.conteudo.pspReference,
            mensagem: response.mensagem,
            sucesso: response.sucesso
          }        
          });
        }
        if(state.advancePaymentMethod.value === "LP"){
          window.open(response.conteudo.link, '_blank', 'noopener,noreferrer');
        }
      }else{
        if(response.erro){
          alert(`Ops! ${response.erro}`);
        } else {
          alert(`Ops! ocorreu um erro, tente novamente mais tarde.`);
        }
      }

    }else{
      alert(`Ops! ocorreu um erro, tente novamente mais tarde.`);
    }


    //setLoading(false);
  };

  return (
    <Section>
      <Header>
        <NavBar backButton blueArrow />
      </Header>
      <MobileView>
        <Container>
          <Row>
            <Col sm={12} md={{ span: 6, offset: 3 }}>
              {isPossibleAdvancePayment &&  <Form
                state={state}
                onSelectChange={onSelectChange}
                onSubmit={onSubmit}
              />}
              {!isPossibleAdvancePayment && <div>
                <h3 className="loovi-header-title loovi-color-4">Solicitação de Antecipação de Fatura</h3>
                <p className="loovi-color-4">{txtIsPossibleAdvancePayment}</p>
              </div>}
            </Col>
          </Row>
        </Container>
      </MobileView>
      <BrowserView>
        <Container fluid>
          <Row>
            <div className="loovi-fixed-side-column">
              <Sidebar />
            </div>
            <div className="loovi-fluid-column">
              <Col sm={12} md={6}>
                <div className="loovi-descktop-padding">
                  {isPossibleAdvancePayment && <Form
                    state={state}
                    onSelectChange={onSelectChange}
                    onSubmit={onSubmit}
                  />}
                  {!isPossibleAdvancePayment && <div>
                    <h3 className="loovi-header-title loovi-color-4">Solicitação de Antecipação de Fatura</h3>
                    <p className="loovi-color-4">{txtIsPossibleAdvancePayment}</p>
                  </div>}
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </BrowserView>
    </Section>
  );
}

function Form({state, onSelectChange, onSubmit}) {
    const { register, handleSubmit, errors, clearError} = useForm();
    //const [isLoading, setLoading] = useState(false)

    const paymentMethods = [
        { label: '-- Método de Pagamento --', value: 'MP', disabled: true },
        { label: 'Pix', value: 'PI', disabled: false },
        { label: 'Cartão de Crédito', value: 'CC', disabled: false },
        { label: 'Link de pagamento', value: 'LP', disabled: false }
      ];

    return (
        <React.Fragment>
        {/* <LoaderAnimation show={isLoading} /> */}
        <h3 className="loovi-header-title loovi-color-4">Solicitação de Antecipação de Fatura</h3>
        <p className="loovi-color-4">É possível solicitar a antecipação do pagamento da sua fatura de forma rápida e prática. Você poderá escolher entre as opções de pagamento disponíveis, como Pix, link de pagamento ou cartão de crédito. Após concluir a solicitação, aguarde o processamento para receber a confirmação do pagamento por e-mail. Em caso de erro durante o processo de pagamento, você poderá realizar a solicitação novamente.</p>
        <span className="loovi-spacer"></span>

        <form onSubmit={handleSubmit(onSubmit)}>
            <label>
                <span>Valor recorrente a antecipar : {state.advancePaymentValue} </span>
            </label>

            <label>
            </label>

            <label>
                    <span>Método de Pagamento para Antecipação</span>
                    <select
                      name="advancePaymentMethod"
                      ref={register({ required: true })}
                      className="loovi-select"
                      value={state.advancePaymentMethod.value}
                      onChange={(e)=>{
                        onSelectChange(e);
                        if (state.advancePaymentMethod.value !== "") return clearError("advancePaymentMethod")
                      }} >

                    {paymentMethods.map((paymentMethod, index) => (
                    <option key={index} value={paymentMethod.value} disabled={paymentMethod.disabled}>{paymentMethod.label}</option>
                    ))}
                </select>
                {errors.advancePaymentMethod && <span className="loovi-msg-error">Selecione um método de pagamento para antecipação</span> }
                
            </label>

            <input type="submit" value="Solicitar Antecipação" />
            {(state.advancePaymentMethod.value === "PI" && state.advancePaymentContent.sucesso) && <div>
              <div>
                <div style={{
                    maxWidth: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                }}>
                  <h1
                      className="loovi-header-title" 
                      style={{
                          fontSize: '24px',
                          marginBottom: '5px'
                      }}
                  >
                      Informações para pagamento com PIX
                  </h1>
                  <p className="loovi-color-4">
                      Abra o app do seu banco, acesse o menu Pix e cole o código para realizar o pagamento. 
                      Se preferir, escanei a imagem do QR Code.
                  </p>
                </div>
                <div style={{marginTop: 20}}>
                  <img src={state.advancePaymentContent.link} alt={'QR Code PIX'} class="loovi-image-center"/>
                  <p
                      style={{
                          maxWidth: '480px',
                          margin: '0 auto',
                          whiteSpace: 'nowrap',
                          overflow: 'auto',
                          alignContent: 'center'
                      }}
                      align="center"
                  >
                      {`${state.advancePaymentContent.chaveAleatoria} `}

                  <img 
                        style={{
                          width: '20px',
                          height: '20px',
                        }} 
                        src={`/img/advancePayment/copy.png`} alt={'Copy'}
                        onClick={() => {navigator.clipboard.writeText(state.advancePaymentContent.chaveAleatoria)}}/>
                  </p>
                </div>
              </div>
            </div>}

            {(state.advancePaymentMethod.value === "CC" && state.advancePaymentContent.sucesso) &&
              <div>
                <span className="loovi-spacer"></span>
                <div className="loovi-sub-header loovi-center">
                    <Container>   
                        <h4 className="loovi-subtitle">{state.advancePaymentContent.mensagem}</h4>
                        <figure className="loovi-check"><img src={checkIconImage} alt="Parabéns"/></figure>
                    </Container>
                </div>
              </div>
            }
        </form>
        </React.Fragment>
    );
}

const Section = styled.section`
  position: relative;
  font-size: 15px;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  h4.loovi-subtitle{

    position: relative;
    font-family: 'BrownStd-Regular';
    font-size: 18px;
    color: #899299;
    letter-spacing: -0.64px;
    text-align: center;
    line-height: 26px;
}

.loovi-sub-header-browser{

    margin-bottom: 60px;
}

.loovi-fluid-column{

    position: relative;
    z-index: 0;

    &::before{

        content: '';
        display: block;
        width: 30px;
        height: 300px;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        top: 0;
    }
}

h5.loovi-body-title{

    font-size: 21px;
    color: #5A78FF;
    letter-spacing: -0.75px;
    line-height: 26px;
}

p{

    font-size: 15px;
}

.loovi-check{

    img{

        width: 44px;
        height: auto;
    }
}

.loovi-image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

`;


