import { URLS, api_keyed } from '../helpers/Request';

export default class VehiclesService {
  static async getVehicleByPlate(plate, isPlanoSemDipositivo) {
    try {
      let response;
      const urls = URLS;
      if (!urls || !urls.PLACAS) {
        throw new Error('URLS function is not returning the expected object');
      }
      if (!isPlanoSemDipositivo) {
        response = await api_keyed.get(`${urls.PLACAS}/dados-atualizados/${plate}`).json();
      } else {
        response = await api_keyed.get(`${urls.PLACAS}/dados-atualizados/${plate}`).json();
      }
      return response;
    } catch (e) {
      console.error('Error fetching vehicle by plate:', e);
      return false;
    }
  }
}
