// Dependencias

import React from 'react'
import styled from 'styled-components'
import menuItens from '../../assets/data/menu-itens.json'
import Version from '../Version'

export const Sidebar = () =>{

    let itens = menuItens.itens.map(item => {
        return(
            <a key={item.id} className="menu-item" href={item.route}>{item.label}</a>
        )
    })

    return(
        <Section>
            {itens}
            <Version color="dark" />
        </Section>
    )

}

const Section = styled.section`
    padding-top: 30px;
    padding-left: 30px;

    &::before{

        content: '';
        display: block;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 1;
    }

    .menu-item{

        display: block;
        padding: 20px;
        border-bottom: solid 1px #c9ccd3;
        font-family: BrownStd-Regular;
        font-size: 18px;
        color: #899299;
        letter-spacing: -0.64px;
        line-height: 26px;
        position: relative;

        &:hover{

            color: #5A78FF;

            &::before{

                content: '';
                display: block;
                background: #5A78FF;
                width: 10px;
                height: 26px;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -13px;
            }
        }
    }
`

export default Sidebar
