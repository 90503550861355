// Dependencias

import React from 'react'
import styled from 'styled-components'

// Componentes

import Header from '../Header'
import NavBar from './NavBar'
import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../painel/Sidebar'
import { MobileView, BrowserView } from 'react-device-detect'

export const Contract = (props) => {

    return(
        <div className="loovi-contract">
            <h2 className="loovi-color-2">{props.contract.label}</h2>
            <h4>Contrato</h4>
            <div className="loovi-spacer"></div>
            <div className="loovi-contrato loovi-color-5">
                <h5>1) Lorem ipsum dolor sit amet, conse  der ctetur adipiscing elit. Nunc sit </h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet aliquam mauris. In consectetur accumsan semper. Maecenas in semper sem. Duis vestibulum lacinia turpis, cursus egestas massa rhoncus scelerisque. Quisque interdum pulvinar ex, sed scelerisque libero fermentum et. Aliquam efficitur in erat sit amet pharetra. Nullam vitae tortor lobortis orci pulvinar ornare. Duis a nisl sed lorem accumsan tempus. Nulla sit amet urna nec turpis sagittis posuere. Nullam purus orci, convallis nec interdum eget, consequat sed urna.</p>

                <h5>2) Lorem ipsum dolor sit amet, conse  der ctetur adipiscing elit. Nunc sit </h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet aliquam mauris. In consectetur accumsan semper. Maecenas in semper sem. Duis vestibulum lacinia turpis, cursus egestas massa rhoncus scelerisque. Quisque interdum pulvinar ex, sed scelerisque libero fermentum et. Aliquam efficitur in erat sit amet pharetra. Nullam vitae tortor lobortis orci pulvinar ornare. Duis a nisl sed lorem accumsan tempus. Nulla sit amet urna nec turpis sagittis posuere. Nullam purus orci, convallis nec interdum eget, consequat sed urna.</p>

                <h5>3) Lorem ipsum dolor sit amet, conse  der ctetur adipiscing elit. Nunc sit </h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet aliquam mauris. In consectetur accumsan semper. Maecenas in semper sem. Duis vestibulum lacinia turpis, cursus egestas massa rhoncus scelerisque. Quisque interdum pulvinar ex, sed scelerisque libero fermentum et. Aliquam efficitur in erat sit amet pharetra. Nullam vitae tortor lobortis orci pulvinar ornare. Duis a nisl sed lorem accumsan tempus. Nulla sit amet urna nec turpis sagittis posuere. Nullam purus orci, convallis nec interdum eget, consequat sed urna.</p>
            </div>
        </div>
    )
}
export class Contracts extends React.Component{

    render(){

        return(
            <Section>
                <Header>
                    <NavBar arrowAction={this.props.showContract} />
                </Header>
                <MobileView>
                    <h3 className="loovi-header-title loovi-color-4">Meus contratos</h3>
                    <Container>
                        <Row>
                            <Col sm={12} md={{span: 6, offset:3}}>
                                <Contract contract={this.props.contract}/>   
                            </Col>
                        </Row>
                    </Container>
                </MobileView>
                <BrowserView>
                    <Container fluid>
                        <Row>
                        <div className="loovi-fixed-side-column">
                            <Sidebar />
                        </div>
                        <div className="loovi-fluid-column">
                            <Col sm={12} md={8}>
                                <div className="loovi-descktop-padding">
                                    <h3 className="loovi-header-title loovi-color-4">Meus contratos</h3>
                                    <Contract contract={this.props.contract}/> 
                                </div>
                            </Col>
                        </div>
                        </Row>
                    </Container>
                </BrowserView>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;

    .loovi-descktop-padding{

        h2,h3,h4{

            text-align: left !important;
        }
    }

    .loovi-contract{

        h2{

        font-family: 'BrownStd-Regular';
            font-size: 18px !important;
            color: #899299;
            letter-spacing: -0.64px;
            text-align: center;
            line-height: 26px;
            margin: 0;
        }

        h4{

            font-size: 15px;
            color: #899299;
            letter-spacing: -0.32px;
            line-height: 21px;
            text-align: center;
        }

        h5{

            text-transform: uppercase;
            font-size: 15px;
            color: #899299;
            letter-spacing: -0.32px;
            line-height: 21px;
            margin: 0;
        }
    }
`
export default Contracts