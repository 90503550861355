import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import checkBlue from "../../assets/images/check-blue.svg"
import ducumentImage from "../../assets/images/file-document-arrow-right-outline.svg"
import cam from "../../assets/images/camera-outline.svg"
import arrow from "../../assets/images/arow-ritgh-gray.svg"
import { resizeImage } from '../../helpers/Resizer';
import Webcam from "react-webcam";
import iconClose from "../../assets/icons/window-close.svg"


export function AttachmentCaptureScreen ({
    title, stepNumber, orientation,
    img, handleUpload, setSelectedFile,
    selectedFile, details, handleNextStep,
    isIncidentReport,
    isAttach=true
}){
    const [isOpenModal, setIsOpenModal] = useState({open: false, typeModal:""})
    const [photoFile, setPhotoFile] = useState("")
    const webcamRef = useRef(null);

    const handleOnClick = ()=>{
        if(selectedFile){
            handleUpload(details)
        }else{
            setIsOpenModal({open: true, typeModal:"select"})
        }
    }

    const onFileChange = async (event) => {
        const file = event.target.files[0];
    
        if (file) {
          let resizedFile = file;
          if (file.type !== "application/pdf") {
            resizedFile = await resizeImage(file);
          }
    
          const reader = new FileReader();
    
          reader.onloadend = (e) => {
            setSelectedFile(e.target.result);
          };
    
          reader.readAsDataURL(resizedFile);
        }

        setIsOpenModal({open: false, typeModal:""})

    };

    const videoConstraints = {
        facingMode: { exact: "environment" },
    };

    const capture = (event) => {
        event.preventDefault();
        const imageSrc = webcamRef.current.getScreenshot();
        
        if(imageSrc){
            setPhotoFile(imageSrc)
            setIsOpenModal({open: true, typeModal:"verify"})
        }
    };

    const handleSendPhoto = ()=>{
        setSelectedFile(photoFile)
        setIsOpenModal({open: false, typeModal:""})
    }

    useEffect(()=>{
        const isExistAprovacao = !!details.Foto && "Aprovacao" in details.Foto
        
        if((details && details.Foto && ((isExistAprovacao && details.Foto.Aprovacao) || !isExistAprovacao)) || (!isIncidentReport && stepNumber === "3")){
            handleNextStep()
        }

        if(isExistAprovacao && !details.Foto.Aprovacao && setSelectedFile.length === 0){
            setIsOpenModal({ open: true, typeModal:"message"})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <Container>
        <Header length={title.length}>
            <h3>{title}</h3> <span>{stepNumber}/8 Fotos</span>
        </Header>

        {selectedFile ?
                <ImageCnh onClick={()=> setIsOpenModal({open: true, typeModal:"select"})}>
                         <img src={selectedFile} alt='CNH' />
                </ImageCnh>
            : 
            <>
                <ul id='list-attachment'>
                {
                    orientation.map((element, index)=>{
                        return <li key={index}>
                        <img src={checkBlue} alt='check' />
                        <p>{element}</p>
                    </li>
                    })
                }
                </ul>
                <CnhBox onClick={()=> setIsOpenModal({open: true, typeModal:"select"})}>
                    <img src={img} alt='CNH' />
                </CnhBox>
            </>
        }

        <div>
            <button 
                onClick={handleOnClick}
            >
                Continuar
            </button>
            {isOpenModal.open && isOpenModal.typeModal=== "select" &&
                <ModalSelect>
                    <div onClick={()=> setIsOpenModal({open: false, typeModal:""})} />
                    <ul>
                        {isAttach && <li>
                                <input type='file' accept=".jpg, .jpeg, .png, .webp" onChange={onFileChange} />
                            <div>
                                <img src={ducumentImage} alt='' />
                                Anexar arquivo
                            </div>
                            <img src={arrow} alt='' />
                        </li>}
                        <li onClick={()=> setIsOpenModal({open: true, typeModal:"photo"})}>
                            <div>
                                <img src={cam} alt='' />
                                Tirar foto
                            </div>
                            <img src={arrow} alt='' />
                        </li>
                    </ul>
                </ModalSelect>
            }
            {
                isOpenModal.open && isOpenModal.typeModal === "photo" &&
                <ModalPhoto>
                    <div className="camera-container">
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            videoConstraints={videoConstraints}
                            screenshotFormat="image/jpeg"
                            style={{ width: "100%", maxWidth: "640px" , height:"100%"  }}
                        />


                        <button onClick={capture}>
                            Capturar
                        </button>
                    </div>
                </ModalPhoto>
            }

            {
                isOpenModal.open && isOpenModal.typeModal === "verify" &&
                <ModalVerify>
                    <img src={photoFile} alt='' />
                    <div>
                        <p>Está nítido?</p>
                        <div>
                            <button onClick={()=> setIsOpenModal({open: true, typeModal:"photo"})}>Não</button>
                            <button onClick={handleSendPhoto}>Sim</button>
                        </div>
                    </div>
                </ModalVerify>
            }

        </div>

        { isOpenModal.open && isOpenModal.typeModal === "message" &&
            <ModalMessagePhoto>
                <div>
                    <img src={iconClose} alt='Fechar' onClick={()=> {setIsOpenModal({open: false, typeModal:""})}} />

                    <div>
                        <h2>{details.Foto.Tipo}</h2>
                        <p>{details.Foto.TextoReprovacao}</p>
                    </div>

                    <button onClick={()=> {setIsOpenModal({open: false, typeModal:""})}}>Continuar</button>
                </div>
            </ModalMessagePhoto>
        }
    </Container>
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    margin-top: 16px;

    & > ul{
        display: flex;
        flex-direction: column;
        gap: 12px;

        padding: 0;
        margin: 0;

        li{
        display: flex;
        align-items: start;
        gap: 8px;

            p{
                font-size: 14px;
                line-height: 18px;
                color: rgba(153, 153, 153, 1);
            
            }
        }
    }

    button{
        width: 100%;
        height: 45px;
        border: 0;
        border-radius: 4px;

        background-color:rgba(90, 120, 255, 1);
        color:white;
    }

`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 8px;
    flex-direction: ${props => props.length > 17 ? "row-reverse" : "row"};
    
    h3{
        font-size: 20px;
        font-weight: 700;
        color: rgba(90, 120, 255, 1);
        margin: 0;
        line-height: 24.2px;
        text-align: start;

        width: ${props => props.length > 17 ? "100%" : "auto"};
        padding-right: ${props => props.length > 17 ? "25px" : "0"};
    }

    span{
        font-size: 12px;
        color: rgba(161, 164, 171, 1);
    }

`

const CnhBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 223px;

`

const ImageCnh = styled.div`
    height: 339px;
    display: flex;
    
    align-content: center;
    justify-content: center;

    img{
        object-fit: contain;
    }
`

const ModalSelect = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 1000;
    background-color: rgba(81, 81, 81, 0.25);

    & >div{
        width: 100%;
        height: 100%;
    }

    ul{
        width: 100%;
        background-color: white;
        border-radius: 24px 24px 0px 0px;
        padding: 8px 0px 8px 0px;

        margin: 0;
    }

    li{
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 56px;
        padding-inline: 20px;

        position: relative;
        div{
            display: flex;
            align-items: center;
            gap: 12px;
        }

        svg{
            width: 24px;
            height: 24px;
        }
    }

    input{
        position: absolute;
        opacity: 0;

        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
    }
`

const ModalPhoto = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    background-color: rgba(81, 81, 81, 0.25);
    
    .camera-container{
        width: 100%;
        max-width: 680px;
        height: 100%;
        background-color: white;
        padding: 10px;

        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    button{
        width: 100%;
        height: 48px;
        margin-top: 0 !important;
        background-color: rgba(90, 120, 255, 1);
        color: white;
    }
`

const ModalVerify = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1000;
    background-color: rgba(81, 81, 81, 0.25);

    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }

    @media (orientation: portrait) {
        img {
            width: 100vw;
            height: 100vh;
            transform: translate(-50%, -50%) rotate(0deg);
        }
    }

    @media (orientation: landscape) {
        img {
            width: 100vh;
            height: 100vw;
            transform: translate(-50%, -50%) rotate(270deg);
        }
    }

    & > div{
        position: absolute;
        bottom: 0;

        height: 117px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.72);

        color: white;
        font-size: 16px;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        div{
            display: flex;
            justify-content: center;
            gap: 22px;
            width: 100%;
        }

        button:first-child{
            background-color: transparent;
            border: 1px solid white;
            
        }

        button{
            width: 120px;
            height: 43px;
            color: white;
            background-color: rgba(90, 120, 255, 1);
            border-radius: 4px;
        }
    }
    
`
const ModalMessagePhoto = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(81, 81, 81, 0.25);

    & > div{
        width: 90%;
        max-width: 470px;
        height: 410px;
        border-radius: 12px;
        background-color: white;
        padding: 16px 16px 32px 16px;

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div{
            display: flex;
            flex-direction: column;
            gap: 32px;
        }

        h2{
            font-weight: 700 !important;
            font-size: 20px !important;
            align-items: center;
            color: rgba(81, 81, 81, 1) !important;
            line-height: 24.2px;
            letter-spacing: normal;

            text-transform: capitalize;
        }

        p{
            color: rgba(161, 164, 171, 1);
            font-size: 14px;
        }

    }

    img{
        width: 24px;
        height: 24px;

        position: absolute;
        top: 16px;
        right: 16px;
    }

    button{
        background-color: rgba(90, 120, 255, 1);
        color: white;
    }

    
`